import firebase from "firebase/app";
import {
  IonList,
  IonButton,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonItem,
  IonModal,
  IonLabel,
  IonToggle,
  IonIcon,
} from "@ionic/react";
import React, { PropsWithChildren, useContext, useState } from "react";
import useAccountValidator from "../../hooks/useAccountValidator";
import { Customer } from "../../models/Customer";
import AccountListItem from "./AccountListItem";
import {
  cardOutline,
  helpCircleOutline,
  happyOutline,
  createOutline,
  businessOutline,
  logOutOutline,
  arrowForwardCircleSharp,
} from "ionicons/icons";
import moment from "moment";
import { RouteComponentProps, useLocation } from "react-router-dom";
import { OptionsContext } from "../../providers/OptionsProvider";

export interface AccountListProps extends RouteComponentProps {
  customer: Customer;
  user: firebase.User;
  setShowLogoutAlert: React.Dispatch<React.SetStateAction<boolean>>;
}

const AccountList: React.FC<PropsWithChildren<AccountListProps>> = (props) => {
  const { customer, user, setShowLogoutAlert } = props;

  const {
    isContactInformationValid,
    isAddressValid,
    isIdentificationValid,
    isQuestionnaireValid,
    isAccountValid,
  } = useAccountValidator(customer);

  const [showDebugModal, setShowDebugModal] = useState<boolean>(false);
  const { options, saveOption } = useContext(OptionsContext);

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const useBtn = searchParams.get("firstTime");

  return (
    <>
      <IonList lines="full">
        <IonItemSliding>
          <IonItemOptions side="start">
            <IonItemOption onClick={() => setShowDebugModal(true)}>
              Debug
            </IonItemOption>
          </IonItemOptions>

          <AccountListItem
            icon={createOutline}
            complete={isContactInformationValid()}
            labelText="Contact Information"
            navigateTo="/account/contact-information"
          >
            <p>Full Name, Email & Phone</p>
          </AccountListItem>
        </IonItemSliding>

        <AccountListItem
          icon={businessOutline}
          complete={isAddressValid()}
          labelText="Address"
          navigateTo="/account/address"
          defaultText="Not Set"
        >
          <p>{customer.address}</p>
          <p>{`${customer.city}, ${customer.province} ${customer.postal}`}</p>
          <p>{customer.country}</p>
        </AccountListItem>

        <AccountListItem
          complete={isIdentificationValid()}
          icon={cardOutline}
          labelText="Identification"
          navigateTo="/account/identification"
          defaultText="Not Set"
        >
          <p>{customer.idType}</p>
          <p>{customer.idNumber}</p>
        </AccountListItem>

        <AccountListItem
          complete={isQuestionnaireValid()}
          icon={helpCircleOutline}
          labelText="Questionnaire"
          navigateTo="/account/questionnaire"
          defaultText="Incomplete"
        >
          <p>Completed</p>
        </AccountListItem>

        <AccountListItem
          icon={happyOutline}
          complete
          labelText="Children"
          navigateTo="/account/children"
        >
          <p>
            {!!customer && !!customer.minors
              ? Object.keys(customer.minors).map((key, index) => {
                  //calculate children age.
                  let comma = "";
                  if (index > 0) comma = ", ";

                  const minor = customer?.minors![key as any];
                  if (!!minor) {
                    let age = "";
                    if (!!minor.dateOfBirth) {
                      const dob = minor.dateOfBirth;
                      const dateObject = moment(
                        `${dob.year}-${dob.month}-${dob.day}`
                      );
                      age = `(${moment().diff(dateObject, "years")} years)`;
                    }
                    return `${comma}${minor.firstName} ${age}`;
                  }
                  return "";
                })
              : "Add children accompanying you here."}
          </p>
        </AccountListItem>
        <IonItem button detail={false} onClick={() => setShowLogoutAlert(true)}>
          <IonIcon slot="start" color="danger" icon={logOutOutline}></IonIcon>
          <IonLabel>Log Out</IonLabel>
        </IonItem>
      </IonList>
      {!!useBtn && (
        <div className="ion-padding">
          <IonButton
            disabled={!isAccountValid()}
            size="default"
            expand="block"
            title="Continue"
            routerLink="/home"
          >
            Continue
            <IonIcon icon={arrowForwardCircleSharp} slot="end" />
          </IonButton>
        </div>
      )}
      <IonModal isOpen={showDebugModal}>
        <IonList>
          <IonItem>
            <IonLabel>UID:</IonLabel>
            {user.uid}
          </IonItem>
          <IonItem>
            <IonLabel>Customer:</IonLabel>
            {customer.customer}
          </IonItem>
          <IonItem>
            <IonLabel>Method:</IonLabel>
            {user.providerData[0]?.providerId}
          </IonItem>
          <IonItem>
            <IonLabel>Manual Location</IonLabel>
            <IonToggle
              checked={options.manualLocation}
              onIonChange={(event) =>
                saveOption({
                  manualLocation: event.detail.checked,
                })
              }
            />
          </IonItem>
        </IonList>
        <IonButton onClick={() => setShowDebugModal(false)}>Close</IonButton>
      </IonModal>
    </>
  );
};

export default AccountList;
