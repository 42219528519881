import {
  IonAlert,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLoading,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { personCircle } from "ionicons/icons";
import React, { useContext, useState } from "react";
import CompleteAccountCard from "../components/cards/CompleteAccountCard";
import InLineCard from "../components/cards/InLineCard";
import RegisterCard from "../components/cards/RegisterCard";
import { UserContext } from "../providers/UserProvider";
import { Redirect } from "react-router-dom";
import useAccountValidator from "../hooks/useAccountValidator";
import useBackend from "../hooks/useBackend";

const HomePage: React.FC = () => {
  const { customer } = useContext(UserContext);
  const { isAccountValid } = useAccountValidator(customer!);

  const [showCompletionAlert, setShowCompletionAlert] =
    useState<boolean>(false);
  const { removeRegistration } = useBackend();

  const [showRemovalAlert, setShowRemovalAlert] = useState<boolean>(false);

  const onRemoveRegistration = async (
    customerId: string,
    registrationId: string,
    location: string,
    queueId: string
  ) => {
    await removeRegistration(customerId, registrationId, location, queueId);

    setShowRemovalAlert(true);
  };

  // Track the registrations that are related to this customer at this range.
  return (
    <>
      <UserContext.Consumer>
        {(ctx) => {
          if (ctx.loadingCustomerState) {
            return <IonLoading isOpen></IonLoading>;
          } else if (!!ctx.customer) {
            return (
              <IonPage>
                <IonHeader>
                  <IonToolbar>
                    <IonTitle>OGC</IonTitle>
                    <IonButtons slot="primary">
                      <IonButton
                        routerLink={
                          "/account" +
                          (!isAccountValid() ? "?firstTime=true" : "")
                        }
                      >
                        <IonIcon slot="icon-only" icon={personCircle} />
                      </IonButton>
                    </IonButtons>
                  </IonToolbar>
                </IonHeader>

                <IonContent>
                  <IonHeader collapse="condense">
                    <IonToolbar>
                      <IonTitle size="large">
                        {!isAccountValid()
                          ? "Welcome!"
                          : `${ctx.customer.firstName} ${ctx.customer.lastName}`}
                      </IonTitle>
                    </IonToolbar>
                  </IonHeader>

                  {!isAccountValid() && <CompleteAccountCard />}
                  {isAccountValid() && !ctx.customer.activeRegistration && (
                    <RegisterCard />
                  )}
                  {isAccountValid() && !!ctx.customer.activeRegistration && (
                    <InLineCard
                      location={ctx.customer.activeRegistration.location}
                      onRemove={() =>
                        onRemoveRegistration(
                          ctx.customer?.customer!,
                          ctx.customer?.activeRegistration!.id!,
                          ctx.customer?.activeRegistration!.location!,
                          ctx.customer?.activeRegistration!.queueId!
                        )
                      }
                    />
                  )}
                </IonContent>
              </IonPage>
            );
          } else {
            return <Redirect to="/connect" />;
          }
        }}
      </UserContext.Consumer>

      <IonAlert
        isOpen={showCompletionAlert}
        onDidDismiss={() => setShowCompletionAlert(false)}
        header={"Thank you for register!"}
        subHeader="Your registration is on queue."
        message={`We send you a message to <strong>${customer?.email}</strong> with the registration information provided.`}
        buttons={["Ok"]}
      />

      <IonAlert
        isOpen={showRemovalAlert}
        onDidDismiss={() => setShowRemovalAlert(false)}
        header={"You have been removed from the line!"}
        buttons={["Ok"]}
      />
    </>
  );
};
export default HomePage;
