import { IonItem, IonLabel, IonSelect, IonSelectOption } from "@ionic/react";
import React from "react";
import { Controller, useForm } from "react-hook-form";

export interface Question {
  negativeIndicator: boolean;
  text: string;
  answer?: boolean;
}

export type EditQuestionCallback = (index: number, data: Question) => void;

/**
 * Manages component model
 * for this component.
 */
export interface QuestionEditItemProps {
  index: number;
  question: Question;
  onSubmitHandler?: EditQuestionCallback;
}

const QuestionEditItem: React.FC<QuestionEditItemProps> = ({
  index,
  question,
  onSubmitHandler,
}) => {
  const { control } = useForm();

  const setAnswer = (answer: boolean) => {
    const data: Question = {
      answer: answer,
      negativeIndicator: question.negativeIndicator,
      text: question.text,
    };

    if (onSubmitHandler !== undefined) {
      onSubmitHandler(index, data);
    }
  };

  const keyDate = new Date();

  return (
    <IonItem key={index}>
      <IonLabel className="ion-text-wrap fixed-label">{question.text}</IonLabel>
      <Controller
        name={index.toString()}
        control={control}
        rules={{ required: "Please select an answer" }}
        render={({ name, value = question.answer }) => (
          <IonSelect
            // required because of bug below
            // https://github.com/ionic-team/ionic-framework/issues/20106
            key={keyDate.toISOString()}
            name={name}
            interface="popover"
            placeholder="Yes / No"
            value={value}
            onIonChange={(e) => setAnswer(e.detail.value)}
            className="fixed-ion-select"
          >
            <IonSelectOption value={true}>Yes</IonSelectOption>
            <IonSelectOption value={false}>No</IonSelectOption>
          </IonSelect>
        )}
      />
    </IonItem>
  );
};

export default QuestionEditItem;
