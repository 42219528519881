import { Customer } from "../models/Customer";

const useAccountValidator = (customer: Customer) => {
  const isNameValid = () => {
    return !!customer.firstName?.trim() && !!customer.lastName?.trim();
  };

  const isPhoneValid = () => {
    return !!customer.phone && !!customer.phone.trim();
  };

  const isEmailValid = () => {
    return !!customer.email && !!customer.email.trim();
  };

  const isAddressValid = () => {
    return (
      !!customer.address?.trim() &&
      !!customer.city?.trim() &&
      !!customer.province?.trim() &&
      !!customer.postal?.trim() &&
      !!customer.country?.trim()
    );
  };

  const isIdentificationValid = () => {
    return !!customer.idType && !!customer.idNumber;
  };

  const isQuestionnaireValid = () => {
    if (!!customer.questionnaire) {
      if (Object.keys(customer.questionnaire).length < 11) return false;

      for (let k of Object.keys(customer.questionnaire)) {
        let question = customer.questionnaire[k as any];
        if (question.answer === undefined) return false;
      }
    } else {
      return false;
    }
    return true;
    // return !!customer.questionnaire;
  };

  const isContactInformationValid = () =>
    isNameValid() && isPhoneValid() && isEmailValid();

  const isAccountValid = () => {
    return (
      isNameValid() &&
      isPhoneValid() &&
      isEmailValid() &&
      isAddressValid() &&
      isIdentificationValid() &&
      isQuestionnaireValid()
    );
  };

  return {
    isNameValid,
    isPhoneValid,
    isEmailValid,
    isAddressValid,
    isIdentificationValid,
    isQuestionnaireValid,
    isAccountValid,
    isContactInformationValid
  };
};

export default useAccountValidator;
