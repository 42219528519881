import { IonIcon, IonItem, IonLabel, IonText } from "@ionic/react";
import React, { PropsWithChildren } from "react";
import { warningSharp } from "ionicons/icons";

export interface AccountListItemProps {
  complete: boolean;
  labelText: string;
  defaultText?: string;
  boldLabel?: boolean;
  navigateTo?: string;
  icon: string;
}

const AccountListItem: React.FC<PropsWithChildren<AccountListItemProps>> = (
  props
) => {
  return (
    <IonItem button routerLink={props.navigateTo}>
      <IonIcon slot="start" color="danger" icon={props.icon}></IonIcon>
      <IonLabel>
        <IonText>
          {!!props.boldLabel ? (
            <strong>{props.labelText}</strong>
          ) : (
            props.labelText
          )}
        </IonText>
        {props.complete
          ? props.children
          : !!props.defaultText && <p>{props.defaultText}</p>}
      </IonLabel>
      {!props.complete && (
        <IonIcon icon={warningSharp} color="warning"></IonIcon>
      )}
    </IonItem>
  );
};

export default AccountListItem;
