import {
  IonButton,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonText,
} from "@ionic/react";
import React, { PropsWithChildren } from "react";
import { arrowForwardCircleSharp } from "ionicons/icons";
import { Controller, useForm } from "react-hook-form";
import { Customer } from "../../models/Customer";
import useBackend from "../../hooks/useBackend";
import { RouteComponentProps } from "react-router-dom";

export interface EmailEditFormProps extends RouteComponentProps {
  customer: Customer;
}

const EmailEditForm: React.FC<PropsWithChildren<EmailEditFormProps>> = ({
  customer,
  history,
}) => {
  const { updateCustomer } = useBackend();
  const { control, errors, register, handleSubmit } = useForm();

  const saveAndContinue = async (formData: Partial<Customer>) => {
    updateCustomer(customer.customer!, formData).then(() => {
      //navigate back to list.
      history.goBack();
    });
  };

  return (
    <>
      <IonText color="muted">
        <h2>Email</h2>
        <p>
          The email address used here will receive registration documents for
          your records.
        </p>
      </IonText>
      <br />
      <form onSubmit={handleSubmit(saveAndContinue)} noValidate>
        <IonList>
          <IonItem>
            <IonLabel position="floating">Email address * </IonLabel>
            <Controller
              name="email"
              control={control}
              render={({ name, onChange, value = customer.email }) => (
                <IonInput
                  type="text"
                  name={name}
                  value={value}
                  onIonChange={onChange}
                  ref={register}
                ></IonInput>
              )}
              rules={{ required: "Email address is required." }}
            />
          </IonItem>

          {errors && errors["email"] && (
            <IonText color="danger" className="ion-padding-start">
              <small>{errors["email"].message}</small>
            </IonText>
          )}

          <IonItem lines="none"></IonItem>
        </IonList>
        <IonButton
          type="submit"
          size="default"
          expand="block"
          title="Save and continue"
        >
          Save and continue
          <IonIcon icon={arrowForwardCircleSharp} slot="end" />
        </IonButton>
      </form>
    </>
  );
};

export default EmailEditForm;
