import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonText,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import React, { useState } from "react";
import {
  Control,
  Controller,
  DeepMap,
  FieldError,
  ValidateResult
} from "react-hook-form";
import { SignatureCanvas } from "./SignatureCanvas";

export interface AgreementCheckProps {
  control?: Control;
  errors: DeepMap<Record<string, any>, FieldError>;
  checked?: boolean;
  currentSignature: string;

  saveSignature(signature: string): void
}

const AgreementCheck: React.FC<AgreementCheckProps> = props => {
  const { control, errors, saveSignature } = props;
  const [openAgreement, setOpenAgreement] = useState<boolean>(false);
  const [acceptAgreement, setAcceptAgreement] = useState<boolean>(false);
  const [scrollToEnd, setScrollToEnd] = useState<boolean>(false);
  const [showSignatureCanvas, setShowSignatureCanvas] = useState<boolean>(false);

  const handleScroll = async (e: any) => {
    // Get scroll element
    if (e.target && e.detaill !== null) {
      const scrollElement = await e.target.getScrollElement();

      const scrollTop = e.detail.scrollTop;
      const scrollHeight =
        scrollElement.scrollHeight - scrollElement.clientHeight;

      const targetPercent = 90;

      let triggerDepth = ((scrollHeight / 100) * targetPercent);

      if (scrollTop > triggerDepth) {
        setScrollToEnd(true);
      }
    } else {
      //something wrong in detect scroll
      setScrollToEnd(true);
    }
  };

  const validateAgreement = (): ValidateResult => acceptAgreement;

  return (
    <>
      <IonItem>
        <IonLabel>Agreement and Release</IonLabel>
        <Controller
          defaultValue={true}
          control={control}
          name="agreement"
          render={({ name }) => (
            <IonCheckbox
              name={name}
              onClick={() => setOpenAgreement(true)}
              checked={acceptAgreement}
              slot="end"
              value="true"
            ></IonCheckbox>
          )}
          rules={{
            validate: validateAgreement
          }}
        ></Controller>
      </IonItem>
      {errors && errors["agreement"] && (
        <IonText color="danger" className="ion-padding-start">
          <small>
            In order to access to the range, you must accept the range rules.
          </small>
        </IonText>
      )}

      <IonModal isOpen={openAgreement}>
        <IonHeader translucent>
          <IonToolbar>
            <IonTitle>Agreement & release</IonTitle>
            <IonButtons slot="end">
              <IonButton
                onClick={() => setOpenAgreement(false)}>
                Dismiss
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent
          fullscreen
          id="agreementContent"
          scrollEvents={true}
          onIonScroll={handleScroll}
        >
          <IonList lines="none">
            <IonItem>
              <IonText>
                <p>
                  The undersigned agrees to abide by all of the range rules and
                  safety rules of Orlando Gun Club, and the undersigned
                  represents that he or she understands all of these rules, and
                  was given the opportunity to ask for clarification of any of
                  the rules before signing this Agreement. The undersigned
                  understands that Orlando Gun Club reserves the right to eject
                  from the premises any individual who violates any of the rules
                  of Orlando Gun Club or otherwise acts in any unsafe manner as
                  determined by Orlando Gun Club. The undersigned agrees to
                  peaceably leave the premises of Orlando Gun Club if so
                  ejected, after paying for all services and merchandise as
                  shown above.
                </p>
                <p>
                  The undersigned further swears, warrants and represents, under
                  penalty of perjury, that all information provided above is
                  true and correct. The undersigned understands that any
                  possession of a firearm by a convicted felon is a serious
                  crime prohibited by Federal law and Florida law, and that
                  Orlando Gun Club will cooperate fully with any and all Federal
                  and State authorities with the investigation and prosecution
                  of such crimes.
                </p>
                <p>
                  In consideration of the acceptance of my participation,
                  directly or as a spectator or observer, in any activity,
                  class, competition, firing range rental, firearm rental, or
                  other use of the facilities of Orlando Gun Club (hereinafter,
                  collectively, “Activity”), THE UNDERSIGNED AGREES TO ASSUME
                  THE RISKS incidental to such participation and, on my own
                  behalf and on behalf of my heirs, executors and
                  administrators, I RELEASE, INDEMNIFY, HOLD HARMLESS, COVENANT
                  NOT TO SUE, AND FOREVER DISCHARGE the Released Parties defined
                  below, of and from all liabilities, claims, actions, damages,
                  costs or expenses of any nature arising out of or in any way
                  connected with my participation in any such Activity. The
                  Released Parties are Caliber Enterprises LLC d/b/a Orlando Gun
                  Club; its parent, related, affiliated and subsidiary
                  companies; and the officers, managers, directors, members,
                  employees, agents, representatives, insurers, attorneys,
                  successors, heirs and assigns of each. The undersigned
                  expressly understands that the Release, Indemnification,
                  Covenant Not to Sue, and Waiver of Liability provisions of
                  this Agreement clearly and unequivocally include and apply to
                  any claims based on the negligence (whether active or
                  passive), ownership of any dangerous instrumentality, action
                  or inaction of or by any of the above Released Parties,
                  including, but not limited to, claims for bodily injury, death
                  and property damage or loss suffered by me as a result of such
                  participation in any Activity. Additionally, the undersigned
                  further agrees to indemnify and hold the Released Parties
                  harmless from all liabilities, claims, actions, damages, costs
                  or expenses of any nature arising out of or in any way
                  connected with my participation in any Activity which results
                  in the personal injury or death of anyone whatsoever, or loss
                  or damage to the property of anyone whatsoever (including the
                  loss of use thereof). Further, I, the undersigned, agree, on
                  my own behalf and on behalf of my heirs, executors and
                  administrators, to pay Orlando Gun Club for any damage to the
                  Released Parties arising in any way out of any Activity and
                  caused by me in any way, negligently or intentionally,
                  including, but not limited to, property damages, personal
                  injury damages, cleaning costs, loss of use damages, business
                  interruption damages, damage to goodwill or reputation,
                  medical costs, counseling costs, and attorney’s fees.
                </p>
                <p>
                  This Agreement shall be governed by the laws of the State of
                  Florida, and any legal action arising out of my participation
                  in any Activity or any legal action relating to the
                  enforcement of this Agreement shall be commenced exclusively
                  in either the Circuit Court of the Ninth Judicial Circuit in
                  and for Orange County, Florida, or the County Court in and for
                  Orange County Florida, as appropriate.
                </p>
                <p>
                  In entering into this Agreement, I hereby grant the Released
                  Parties a limited power of attorney and authorization to
                  obtain, at my cost, any and all emergency medical treatment
                  which may be needed by myself as a result of participation in
                  any Activity. For the purposes of this Agreement, emergency
                  medical treatment means medical care or treatment necessitated
                  by a sudden, unexpected situation or occurrence resulting in a
                  serious medical condition demanding immediate medical
                  attention. However, I release, indemnify, hold harmless,
                  covenant not to sue, and forever discharge the Released
                  Parties of and from all liabilities, claims, actions, damages,
                  costs or expenses of any nature arising out of or in any way
                  connected with the exercise or the failure to exercise such
                  limited power of attorney and authorization, whether negligent
                  or otherwise.
                </p>

                <p>
                  I expressly agree that this Agreement is intended to be as
                  broad and inclusive as permitted by law, and that if any
                  provision of this Agreement is held illegal, invalid or
                  otherwise unenforceable, the enforceability of the remaining
                  provisions shall not be impaired thereby, and such invalid
                  part, term or provision shall not be deemed part of this
                  Agreement. I further agree that any ambiguities in this
                  Agreement shall not be construed in favor or against any party
                  by virtue of that party having drafted the Agreement. No
                  remedy conferred by any of the specific provisions of this
                  Agreement is intended to be exclusive of any other remedy, and
                  each and every remedy shall be cumulative and shall be in
                  addition to every other remedy now or hereafter existing at
                  law or in equity or by statute or otherwise. The election of
                  any one or more remedy hereunder shall not constitute any
                  waiver of the right to pursue other available remedies.
                </p>
                <p>
                  I certify that I am eighteen (18) years of age or older and
                  that I am entering into this Agreement on my own behalf. I
                  expressly understand that the aforementioned Activity includes
                  the discharge of firearms and the firing of live ammunition. I
                  expressly agree to wear appropriate eye protection and ear
                  protection while on any firing range. I further certify that I
                  have completely read the foregoing and I expressly agree to
                  all of the provisions of this Agreement.
                </p>
              </IonText>
            </IonItem>
          </IonList>
        </IonContent>

        <IonButton
          className="btn-disabled"
          disabled={!scrollToEnd}
          onClick={() => setShowSignatureCanvas(true)}>
          I Agree
        </IonButton>
      </IonModal>

      <IonModal isOpen={showSignatureCanvas}>
        <SignatureCanvas
          saveSignature={(signature: string) => {
            setOpenAgreement(false);

            setAcceptAgreement(true);
            saveSignature(signature);

            setShowSignatureCanvas(false);
          }}
          close={() => setShowSignatureCanvas(false)}
        >
        </SignatureCanvas>
      </IonModal>
    </>
  );
};

export default AgreementCheck;
