import {
  IonPage,
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonLoading,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { UserContext } from "../../providers/UserProvider";
import PhoneEditForm, {
  PhoneFormData,
} from "../../components/account/PhoneEditForm";
import useBackend from "../../hooks/useBackend";
import { Customer } from "../../models/Customer";
import { Country } from "../../models/Country";

const PhonePage: React.FC<RouteComponentProps> = (props) => {
  const { updateCustomer, getCountries } = useBackend();
  const { customer, setCustomer } = useContext(UserContext);
  const [countries, setCountries] = useState<Country[]>([]);
  const { history } = props;

  useEffect(() => {
    const callGetCountries = async () => setCountries(await getCountries())

    callGetCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnSubmit = (formData: PhoneFormData) => {
    if (!!customer && !!customer.customer && !!setCustomer) {
      let data: Partial<Customer> = {
        phone: formData.phone,
      };
      console.log(customer, data);
      updateCustomer(customer.customer, data).then((c) => {
        setCustomer(c);
        //navigate back to list.
        history.goBack();
      });
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Account / Phone</IonTitle>
          <IonButtons slot="start">
            <IonBackButton text="Back" defaultHref="/account" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <UserContext.Consumer>
          {(ctx) => {
            if (!!ctx.customer) {
              return (
                <PhoneEditForm
                  countries={countries}
                  phone={ctx.customer.phone}
                  onSubmit={handleOnSubmit}
                />
              );
            }
            return <IonLoading isOpen={true} />;
          }}
        </UserContext.Consumer>
      </IonContent>
    </IonPage>
  );
};

export default PhonePage;
