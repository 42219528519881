import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

firebase.initializeApp({
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
});

if (process.env.NODE_ENV === "development") {
  //firebase.auth().useEmulator("http://localhost:9099/");
  //firebase.auth().settings.appVerificationDisabledForTesting = true;
  //firebase.database().useEmulator("localhost", 9000);
}

export const auth = firebase.auth();
export const db = firebase.database();
