import firebase from "firebase/app";
import React, {
  createContext,
  PropsWithChildren,
  useEffect,
  useState
} from "react";
import { auth, db } from "../firebase";
import { Customer } from "../models/Customer";
import { RangeLocation } from "../models/RangeLocation";

export interface UserContextProps extends PropsWithChildren<{}> {
  user: firebase.User | null;
  customer: Customer | null;
  rangeLocation: RangeLocation | null;
  authenticated: boolean;
  setUser: React.Dispatch<React.SetStateAction<firebase.User | null>>;
  loadingAuthState: boolean;
  setCustomer: React.Dispatch<React.SetStateAction<Customer | null>>;
  loadingCustomerState: boolean;
  setRangeLocation: React.Dispatch<React.SetStateAction<RangeLocation | null>>;
}

export const UserContext = createContext<Partial<UserContextProps>>({
  user: null,
  customer: null
});

export default function UserProvider(props: PropsWithChildren<{}>) {
  const [user, setUser] = useState<firebase.User | null>(null);
  const [customer, setCustomer] = useState<Customer | null>(null);
  // const [phone, setPhone] = useState<string | null>(null);
  // const [email, setEmail] = useState<string | null>(null);
  const [loadingAuthState, setLoadingAuthState] = useState(true);
  const [loadingCustomerState, setLoadingCustomerState] = useState(true);
  // const [rangeLocation, setRangeLocation] = useState<RangeLocation | null>(
  //   null
  // );


  // Connecting Context
  // const [customerConnected, setCustomerConnected] = useState<boolean>(false);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setUser(user);
      setLoadingAuthState(false);
    });
  }, []);

  useEffect(() => {
    const getCustomerByUid = async () => {
      if (user?.uid) {
        const snapshot = await db
          .ref("/customers")
          .orderByChild("uid")
          .equalTo(user.uid)
          .limitToFirst(1)
          .once("value");

        if (snapshot.exists()) {
          const customerSnapshot = snapshot.toJSON()! as Customer[];

          for (let customerValue of Object.values(customerSnapshot)) {
            const customerRef = db.ref(`/customers/${customerValue.customer}`);

            customerRef.on("value", async (snapshot) => {
              const customer = snapshot.val();

              if (customer.activeRegistration) {
                const registration = await db
                  .ref(`/registrations/${customer.activeRegistration}`)
                  .once("value");

                const { location, queueId } = registration.val();

                customer.activeRegistration = {
                  id: registration.key!,
                  location,
                  queueId
                };
              }

              setCustomer(customer);
              setLoadingCustomerState(false);
            });
          }
        } else {
          setLoadingCustomerState(false);
        }
      }
    };

    getCustomerByUid();
  }, [user]);

  // useEffect(() => {
  //   if (user?.email != null) {
  //     setEmail(user.email);
  //   }
  // }, [user]);

  // useEffect(() => {
  //   if (phone) {
  //     db.ref("/customers")
  //       .orderByChild("phone")
  //       .equalTo(phone)
  //       .limitToFirst(1)
  //       .once("value")
  //       .then((snapshot) => {
  //         snapshot.forEach((s) => setCustomer(s.val() as Customer));
  //       });
  //   }
  // }, [phone]);

  // useEffect(() => {
  //   if (email) {
  //     db.ref("/customers")
  //       .orderByChild("email")
  //       .equalTo(email)
  //       .limitToFirst(1)
  //       .once("value")
  //       .then((snapshot) => {
  //         snapshot.forEach((s) => setCustomer(s.val() as Customer));
  //       });
  //   }
  // }, [email]);

  return (
    <UserContext.Provider
      value={{
        user,
        authenticated: user !== null,
        setUser,
        loadingAuthState,
        customer,
        setCustomer,
        loadingCustomerState
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
}
