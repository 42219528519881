import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import React, { useContext, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { arrowForwardCircleSharp } from "ionicons/icons";
import { RouteComponentProps } from "react-router-dom";
import { UserContext } from "../../providers/UserProvider";
import { auth } from "../../firebase";

const LoginEmailConfirmPage: React.FC<RouteComponentProps> = (props) => {
  const { handleSubmit, control } = useForm<{ email: string }>();
  const { setUser } = useContext(UserContext);

  useEffect(() => {
    const savedEmail = window.localStorage.getItem("emailForSignIn") as string;
    const receivedEmail = new URLSearchParams(props.location.search).get("email");

    control.setValue("email", savedEmail);

    if (savedEmail === receivedEmail) {
      onLogin({email: savedEmail});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLogin = (data: { email: string }) => {
    // The client SDK will parse the code from the link for you.
    auth
      .signInWithEmailLink(data.email, window.location.href)
      .then(function(result) {

        const user = result.user;
        if (!!setUser) {
          setUser(user);
          props.history.push("/home");
        }
      })
      .catch(function(error) {
        // Some error occurred, you can inspect the code: error.code
        // Common errors could be invalid email and invalid or expired OTPs.
        console.error(error);
      });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Login using email</IonTitle>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/home" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <form onSubmit={handleSubmit(onLogin)}>
          <IonList lines="none">
            <IonItem>
              <div>
                <h3>Please confirm your email to access to your account.</h3>
                <p>
                  Welcome to our app! For your safefy please re-type your email
                  address and confirm your sign-in
                </p>
              </div>
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Email</IonLabel>
              <Controller
                render={({ value, onChange }) => (
                  <IonInput value={value} type="email" onIonChange={onChange} />
                )}
                control={control}
                type="email"
                name="email"
                defaultValue={window.localStorage.getItem("emailForSignIn")}
                rules={{
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Invalid Email Address"
                  }
                }}
              />
            </IonItem>

            <div className="ion-padding">
              <IonButton type="submit" expand="block">
                <IonIcon icon={arrowForwardCircleSharp} slot="end" />
                Login
              </IonButton>
            </div>
          </IonList>
        </form>
      </IonContent>
    </IonPage>
  );
};
export default LoginEmailConfirmPage;
