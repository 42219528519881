import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLoading,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import IdentificationEditForm from "../../components/account/IdentificationEditForm";
import useBackend from "../../hooks/useBackend";
import { UserContext } from "../../providers/UserProvider";

/**
 * Holds references to form fields
 */
export interface IdentificationFormData {
  idType?: string;
  idNumber?: string;
}

const IdentificationPage: React.FC<RouteComponentProps> = (props) => {
  const { updateCustomer } = useBackend();
  const { setCustomer, customer } = useContext(UserContext);
  const { history } = props;

  const saveAndContinue = async (formData: IdentificationFormData) => {
    const customerData = {
      idNumber: formData.idNumber,
      idType: formData.idType,
    };

    if (!!customer?.customer) {
      updateCustomer(customer?.customer, customerData).then((c) => {
        console.log(c);
        if (!!setCustomer) setCustomer(c);

        history.goBack();
      });
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Account / Identification </IonTitle>
          <IonButtons slot="start">
            <IonBackButton text="Back" defaultHref="/account" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="ion-padding">
        <IonText color="muted">
          <h2>ID information</h2>
          <p>
            Your ID will be verified at the counter, and is required for using
            the range.
          </p>
        </IonText>
        <UserContext.Consumer>
          {(ctx) => {
            if (!!ctx.customer) {
              return (
                <IdentificationEditForm
                  customer={ctx.customer}
                  setCustomer={ctx.setCustomer!}
                  onSubmit={saveAndContinue}
                  {...props}
                ></IdentificationEditForm>
              );
            }

            return <IonLoading isOpen={true} />;
          }}
        </UserContext.Consumer>
      </IonContent>
    </IonPage>
  );
};

export default IdentificationPage;
