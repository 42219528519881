import {
  IonButton,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonText,
} from "@ionic/react";
import React, { PropsWithChildren } from "react";
import { arrowForwardCircleSharp } from "ionicons/icons";
import { Controller, useForm } from "react-hook-form";
import { Customer } from "../../models/Customer";
import useBackend from "../../hooks/useBackend";
import { RouteComponentProps } from "react-router-dom";

export interface NameEditFormProps extends RouteComponentProps {
  customer: Customer;
  setCustomer: React.Dispatch<React.SetStateAction<Customer | null>>;
}

const NameEditForm: React.FC<PropsWithChildren<NameEditFormProps>> = ({
  customer,
  setCustomer,
  history,
}) => {
  const { updateCustomer } = useBackend();
  const { control, errors, register, handleSubmit } = useForm();

  const saveAndContinue = async (formData: Partial<Customer>) => {
    updateCustomer(customer.customer!, formData).then((c) => {
      //navigate back to list.
      setCustomer(c);
      history.goBack();
    });
  };

  return (
    <>
      <IonText color="muted">
        <h2>Full Name</h2>
        <p>Please, enter your full name</p>
      </IonText>
      <br />
      <form onSubmit={handleSubmit(saveAndContinue)} noValidate>
        <IonList>
          <IonItem>
            <IonLabel position="floating">First name</IonLabel>
            <Controller
              name="firstName"
              control={control}
              render={({ name, onChange, value = customer.firstName }) => (
                <IonInput
                  type="text"
                  name={name}
                  value={value}
                  onIonChange={onChange}
                  ref={register}
                ></IonInput>
              )}
              rules={{ required: "First name is required." }}
            />
          </IonItem>

          {errors && errors["firstName"] && (
            <IonText color="danger" className="ion-padding-start">
              <small>{errors["firstName"].message}</small>
            </IonText>
          )}

          <IonItem>
            <IonLabel position="floating">Last name</IonLabel>
            <Controller
              name="lastName"
              control={control}
              render={({ name, onChange, value = customer.lastName }) => (
                <IonInput
                  type="text"
                  name={name}
                  value={value}
                  onIonChange={onChange}
                  ref={register}
                ></IonInput>
              )}
              rules={{ required: "Last name is required." }}
            />
          </IonItem>

          {errors && errors["lastName"] && (
            <IonText color="danger" className="ion-padding-start">
              <small>{errors["lastName"].message}</small>
            </IonText>
          )}

          <IonItem lines="none"></IonItem>
        </IonList>

        <IonButton
          type="submit"
          size="default"
          expand="block"
          title="Save and continue"
        >
          Continue
          <IonIcon icon={arrowForwardCircleSharp} slot="end" />
        </IonButton>
      </form>
    </>
  );
};

export default NameEditForm;
