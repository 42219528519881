import {
  Geolocation,
  Coordinates,
  PositionError
} from "@ionic-native/geolocation";
import { useEffect, useState } from "react";
import useBackend from "./useBackend";
import { RangeLocation } from "../models/RangeLocation";
import { default as getDistance } from "@turf/distance";


interface DistanceError {
  message: string
}

const useLocation = () => {
  const [ coordinates, setCoordinates ] = useState<Coordinates>();
  const [ rangeLocations, setRangeLocations ] = useState<RangeLocation[]>();
  const [ range, setRange ] = useState<RangeLocation>();
  const [ locationError, setLocationError ] = useState<PositionError | DistanceError>();

  const { getLocations } = useBackend();

  useEffect(() => {
    const getRangeLocations = async () => setRangeLocations(await getLocations());

    const getCoordinates = async () => {
      Geolocation.getCurrentPosition({
        enableHighAccuracy: true,
        timeout: 50000
      })
        .then(position => setCoordinates(position.coords))
        .catch(error => setLocationError(error));

    };

    getCoordinates();
    getRangeLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (coordinates && rangeLocations) {
      let rangeFound = false;

      for (const location of rangeLocations) {

        const distance = getDistance(
          [ location.coordinates.longitude, location.coordinates.latitude ],
          [ coordinates.longitude, coordinates.latitude ],
          { units: "meters" }
        );

        if (distance < 200) {
          setRange(location);
          rangeFound = true;

          break;
        }
      }

      if (!rangeFound) {
        setLocationError(
          {
            message: "Device GPS location not close enough to any shooting range "
          }
        );
      }
    }
  }, [ coordinates, rangeLocations ]);

  return { locations: rangeLocations, range, locationError };
};

export default useLocation;