import {
  IonImg,
  IonItem,
  IonLabel,
  IonModal,
  IonThumbnail
} from "@ionic/react";
import React, { useState } from "react";
import { SignatureCanvas } from "./SignatureCanvas";


interface SignatureModalProps {
  currentSignature: string;

  saveSignature(signature: string): void
}

export const SignatureButton: React.FC<SignatureModalProps> = (props) => {
  const [showSignatureCanvas, setShowSignatureCanvas] = useState<boolean>(false);

  const save = (signature: string) => {
    props.saveSignature(signature);

    setShowSignatureCanvas(false);
  };

  return (
    <>
      {
        !props.currentSignature
          ?
          <IonItem button onClick={() => setShowSignatureCanvas(true)}>
            <IonLabel>
              <IonLabel>Tap here to add your signature</IonLabel>
            </IonLabel>
          </IonItem>
          :
          <IonItem button onClick={() => setShowSignatureCanvas(true)}>
            <IonThumbnail slot="start">
              <IonImg
                src={props.currentSignature}
                style={{ objectFit: "scale-down" }} />
            </IonThumbnail>
            <IonLabel>
              <IonLabel>Tap to retake</IonLabel>
            </IonLabel>
          </IonItem>
      }

      <IonModal isOpen={showSignatureCanvas}>
        <SignatureCanvas
          saveSignature={save}
          close={() => setShowSignatureCanvas(false)}
        >
        </SignatureCanvas>
      </IonModal>
    </>
  );
};

export default SignatureButton;