import {
  IonInput,
  IonItem,
  IonLabel,
  IonNote,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import PhoneNumber from "awesome-phonenumber";
import React, { useEffect, useState } from "react";
import { Country } from "../models/Country";

export interface PhoneNumberInputProps {
  value?: string | null;
  onChange: any;
  countries: Country[];
}

export interface PhoneNumberInputValue {
  phoneNumber: string;
  significantDigits: string;
  countryCode: number;
  regionCode: string;
}

const PhoneNumberInput: React.FC<React.PropsWithRef<PhoneNumberInputProps>> = ({
  value,
  onChange,
  countries,
}) => {
  const [inputValue, setInputValue] = useState<PhoneNumberInputValue>();

  const parseValue = (phone: string) => {
    const parsed = new PhoneNumber(phone);
    let regionCode = parsed.getRegionCode();
    switch (regionCode) {
      case "PR":
        regionCode = "US";
        break;
      case "GG":
        regionCode = "GB";
    }

    const insertValue = {
      phoneNumber: parsed.getNumber(),
      significantDigits: parsed.getNumber("significant"),
      countryCode: parsed.getCountryCode(),
      regionCode,
    };

    setInputValue(insertValue);
  };

  useEffect(() => {
    if (value) {
      parseValue(value);
    } else {
      setInputValue({
        phoneNumber: "",
        significantDigits: "",
        countryCode: 0,
        regionCode: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (inputValue) {
      onChange(inputValue.phoneNumber);
    }
  }, [inputValue, onChange]);

  const handleRegionChange = (value: string) => {
    if (inputValue) {
      parseValue(
        `+${PhoneNumber.getCountryCodeForRegionCode(value)}${
          inputValue?.significantDigits
        }`
      );
    }
  };

  const handleNumberChange = (value?: string | null) => {
    if (inputValue && value && value.length > 2) {
      parseValue(`+${inputValue.countryCode}${value}`);
    }
  };

  const x = new Date();

  return (
    <>
      {countries.length && (
        <IonItem>
          <IonLabel position="fixed">Country</IonLabel>
          <IonSelect
            // required because of bug below
            // https://github.com/ionic-team/ionic-framework/issues/20106
            key={x.toISOString()}
            value={inputValue?.regionCode}
            placeholder="Select Country"
            onIonChange={(e) => handleRegionChange(e.detail.value)}
            name="region"
          >
            {countries.map((country) => (
              <IonSelectOption value={country.regionCode} key={country.name}>
                {country.name} (+{country.callingCode})
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>
      )}

      <IonItem>
        <IonLabel position="fixed">Phone</IonLabel>

        <IonInput
          autocomplete="tel"
          autofocus
          clearOnEdit
          enterkeyhint="send"
          inputmode="tel"
          name="phoneNumber"
          placeholder="Phone Number"
          required
          type="tel"
          value={inputValue?.significantDigits}
          onIonChange={(e) => handleNumberChange(e.detail.value)}
        />
      </IonItem>

      <IonItem lines="none" style={{ "--min-height": "30px" }}>
        <IonNote color="danger">
          You must be able to receive text messages
        </IonNote>
      </IonItem>
    </>
  );
};

export default PhoneNumberInput;
