import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonLoading,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import PhoneNumber from "awesome-phonenumber";
import { callSharp, mailSharp } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { PhoneFormData } from "../../components/account/PhoneEditForm";
import PhoneNumberInput from "../../components/PhoneNumberInput";
import usePhoneLogin from "../../hooks/usePhoneLogin";
import { UserContext } from "../../providers/UserProvider";
import useBackend from "../../hooks/useBackend";
import { Country } from "../../models/Country";

const LoginPhonePage: React.FC<RouteComponentProps> = (props) => {
  const { sendSMS } = usePhoneLogin(window);
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState<string>();
  const { handleSubmit, errors, control } = useForm<PhoneFormData>();
  const { getCountries } = useBackend();
  const [countries, setCountries] = useState<Country[]>([]);

  // const onChangeValue = (value: string) => {
  //   setPhone(value);
  // };

  useEffect(() => {
    const callGetCountries = async () => setCountries(await getCountries())

    callGetCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // useEffect(() => {
  //   reset({
  //     phone: phone || "",
  //   });
  // }, [phone, reset]);

  const onSubmit = (data: PhoneFormData) => {
    console.log("sending SMS", data.phone);
    setIsSending(true);
    setError(undefined);
    sendSMS(data.phone)
      .then((result) => {
        console.info(`SMS Authentication: SMS sent to: ${data.phone}`);
        setIsSending(false);
        props.history.push("/login/phone/confirm");
      })
      .catch((error) => {
        console.error(
          `SMS Authentication: Error sending SMS to ${data.phone}. ${error.message}`
        );
        setError(error.message);
        setIsSending(false);
      });
  };

  return (
    <>
      <UserContext.Consumer>
        {(ctx) => {
          if (ctx.user) {
            return <Redirect to="/home" />;
          } else {
            return (
              <IonPage>
                <IonHeader translucent>
                  <IonToolbar>
                    <IonTitle>OGC</IonTitle>
                  </IonToolbar>
                </IonHeader>
                <IonContent fullscreen>
                  <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    {/* {phone} */}
                    <IonHeader collapse="condense">
                      <IonToolbar>
                        <IonTitle size="large">Orlando Gun Club</IonTitle>
                      </IonToolbar>
                    </IonHeader>
                    <div className="ion-padding">
                      <IonText>
                        <p>Get started by entering your phone number.</p>
                      </IonText>
                    </div>

                    {/* <PhoneNumberForm onPhoneSubmit={sendText} /> */}
                    <Controller
                      control={control}
                      name="phone"
                      defaultValue=""
                      render={({ value, onChange }) => (
                        <PhoneNumberInput countries={countries} value={value} onChange={onChange} />
                      )}
                      rules={{
                        required: "Phone is required",
                        validate: (value) =>
                          new PhoneNumber(value).isValid() ||
                          "Invalid Phone Number"
                      }}
                    ></Controller>
                    <div className="ion-padding">
                      {error && (
                        <IonText color="danger">
                          <p>{error}</p>
                        </IonText>
                      )}
                      <IonText color="danger">{errors.phone?.message}</IonText>
                      <IonButton expand="block" type="submit" id="sendText">
                        <IonIcon slot="start" icon={callSharp} />
                        Send Text Message
                      </IonButton>
                    </div>
                    <div className="ion-text-center">
                      <h4>OR</h4>
                    </div>
                    <div className="ion-padding">
                      <IonButton
                        expand="block"
                        color="secondary"
                        routerDirection="forward"
                        routerLink="/login/email"
                      >
                        <IonIcon slot="start" icon={mailSharp} />
                        Use My Email Address
                      </IonButton>
                    </div>
                    <IonLoading
                      cssClass="my-custom-class"
                      isOpen={isSending}
                      onDidDismiss={() => setIsSending(false)}
                    />
                    <div id="smsRecaptcha" />
                  </form>
                </IonContent>
              </IonPage>
            );
          }
        }}
      </UserContext.Consumer>
    </>
  );
};

export default LoginPhonePage;
