import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFooter,
  IonHeader,
  IonPage, IonRow,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import SignaturePad from "react-signature-canvas";
import React, { useCallback, useEffect, useState } from "react";
import ReactSignatureCanvas from "react-signature-canvas";

interface SignatureModalProps {
  saveSignature(signature: string): void

  close(): void
}

export const SignatureCanvas: React.FC<SignatureModalProps> = (props) => {
  let signatureCanvas: ReactSignatureCanvas;

  const [ canvasDimensions, setCanvasDimensions ] = useState<{ width: number, height: number }>(
    { width: window.innerWidth, height: window.innerHeight }
  );

  const saveCanvasDimensions = useCallback(() => {
    setCanvasDimensions({
      width: window.innerWidth,
      height: window.innerHeight
    });
  }, [ setCanvasDimensions ]);

  useEffect(() => {
    setTimeout(saveCanvasDimensions, 100);
    window.addEventListener("resize", saveCanvasDimensions);

    return () => window.removeEventListener("resize", saveCanvasDimensions);

    // eslint-disable-next-line
  }, []);

  const save = (signature: string) => {
    props.saveSignature(signature);
  };

  return <>
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Add Your Signature</IonTitle>

          <IonButtons slot="end">
            <IonButton color="danger" onClick={ props.close }>
              Cancel
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <SignaturePad
          canvasProps={ {
            width: canvasDimensions.width,
            height: canvasDimensions.height
          } }
          ref={ ref => { signatureCanvas = ref!; } }
        />
      </IonContent>

      <IonFooter>
        <IonToolbar>
          <IonRow>
            <IonCol>
              <IonButton
                expand="block"
                color="medium"
                onClick={ () => signatureCanvas.clear() }>
                Clear
              </IonButton>
            </IonCol>
            <IonCol>
              <IonButton
                expand="block"
                color="success"
                onClick={ () => save(signatureCanvas.getTrimmedCanvas()
                  .toDataURL("image/png")
                ) }>
                Save
              </IonButton>
            </IonCol>
          </IonRow>
        </IonToolbar>

      </IonFooter>
    </IonPage>
  </>;
};