import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonLoading,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { addSharp, arrowForwardCircleSharp } from "ionicons/icons";
import { Controller, useForm } from "react-hook-form";
import PersonaRegisterItem, {
  PersonaRegisterItemProps,
  SelectPersonCallback,
} from "../../components/cards/PersonaRegisterItem";
import RulesCheck from "../../components/cards/RulesCheck";
import AgreementCheck from "../../components/cards/AgreementCheck";
import SignatureButton from "../../components/cards/SignatureButton";
import { OptionsContext } from "../../providers/OptionsProvider";
import useLocation from "../../hooks/useLocation";
import { UserContext } from "../../providers/UserProvider";
import { RouteComponentProps } from "react-router-dom";
import useBackend from "../../hooks/useBackend";

const GetInLine: React.FC<RouteComponentProps> = (props) => {
  const [persona, setPersona] = useState<PersonaRegisterItemProps[]>([]);
  const [signatureString, setSignatureString] = useState<string>("");
  const [showLoading, setShowLoading] = useState<boolean>(false);

  const {
    options: { manualLocation },
  } = useContext(OptionsContext);
  const { handleSubmit, register, control, errors } = useForm();
  const { locations, range } = useLocation();
  const { customer } = useContext(UserContext);
  const { createRegistration, syncRegistration } = useBackend();

  useEffect(() => {
    let items: PersonaRegisterItemProps[] = [];
    if (!!customer) {
      if (!!customer.minors) {
        Object.keys(customer.minors).map((key: string, index: number) => {
          if (!!customer.minors) {
            const minor = customer.minors[key as any];
            items.push({
              guid: key,
              firstName: minor.firstName,
              lastName: minor.lastName,
              checked: false,
            });
          }
          return true;
        });
        setPersona(items);
      }
    }
  }, [customer]);

  const saveSignature = (signature: string) => setSignatureString(signature);

  const onPersonaSelectedChanged: SelectPersonCallback = (
    id: string,
    status: boolean
  ) => {
    // find the index of the element in the persona array.
    const index = persona.findIndex((element) => element.guid === id);

    // Check if the element exists in the array.
    if (index >= 0) {
      // Create a copy of the array since persona is a constant.
      let personasCopy = persona;
      // Update persona status.
      personasCopy[index].checked = status;
      // Update state of the persona array.
      setPersona(personasCopy);
    }
  };

  const onSubmit = async (data: any) => {
    setShowLoading(true);

    let ranges = { pistol: false, rifle: false };

    // Sets the current range
    switch (data.range) {
      case "Pistol":
        ranges.pistol = true;
        break;
      case "Rifle":
        ranges.rifle = true;
        break;
      case "Both":
        ranges.rifle = true;
        ranges.pistol = true;
        break;
      default:
        break;
    }

    const rental = data.rental === "Yes";

    if (!!customer) {
      const registration = {
        ...customer,
        ranges: ranges,
        location: manualLocation ? data.location : range?.id,
        rental: rental,
        completed: true,
        updated: new Date().getTime(),
        created: new Date().getTime(),
        signature: signatureString,
        step: "completed",
      };

      // clear minors then add again with ids
      registration.minors = [];
      // Iterates over minors.
      if (!!customer.minors && !!persona) {
        persona.forEach((value, index) => {
          if (value.checked) {
            // Find customer.minors to
            registration.minors?.push(customer.minors![value.guid as any]);
          }
        });
      }

      // Exposes an event with the register data.
      console.log("create registration");
      const registrationId = await createRegistration(registration);
      console.log(registrationId);

      await syncRegistration(registrationId);

      setShowLoading(false);

      props.history.replace("/home");
    }
  };

  return (
    <>
      <IonPage>
        <IonHeader translucent>
          <IonToolbar>
            <IonTitle>Get in Line</IonTitle>

            <IonButtons slot="start">
              <IonBackButton defaultHref="home" />
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent fullscreen>
          <form onSubmit={handleSubmit(onSubmit)} className="ion-no-padding">
            <IonList>
              {manualLocation && locations && (
                <>
                  <IonListHeader>Choose the Location</IonListHeader>

                  <IonItem>
                    <Controller
                      name="location"
                      defaultValue=""
                      control={control}
                      rules={{ required: "Select one location" }}
                      render={({ name }) => (
                        <IonSelect
                          interface="action-sheet"
                          name={name}
                          placeholder="Select Location"
                          ref={register({ required: "Select one location" })}
                        >
                          {locations.map((location) => (
                            <IonSelectOption
                              value={location.id}
                              key={location.id}
                            >
                              {location.name}
                            </IonSelectOption>
                          ))}
                        </IonSelect>
                      )}
                    ></Controller>
                  </IonItem>

                  {errors && errors["location"] && (
                    <IonText color="danger" className="ion-padding-start">
                      <small>{errors["location"].message}</small>
                    </IonText>
                  )}
                </>
              )}

              <IonListHeader>Who's Shooting?</IonListHeader>

              <IonItem key={customer?.customer}>
                <IonLabel className="custom-label-disabled">
                  {`${customer?.firstName} ${customer?.lastName}`}
                </IonLabel>

                <IonCheckbox
                  color="primary"
                  checked={true}
                  disabled
                  className="custom-item-disabled"
                  slot="start"
                ></IonCheckbox>
              </IonItem>
              {persona.map((el) => (
                <PersonaRegisterItem
                  key={el.guid}
                  guid={el.guid}
                  firstName={el.firstName}
                  lastName={el.lastName}
                  checked={el.checked}
                  onChangeHandler={onPersonaSelectedChanged}
                ></PersonaRegisterItem>
              ))}

              <IonItem button routerLink="/account/children/edit">
                <IonIcon icon={addSharp} slot="start" color="primary" />

                <IonLabel>
                  <IonText color="primary">Add Child</IonText>
                </IonLabel>
              </IonItem>

              <IonListHeader>Range and Rental</IonListHeader>

              <IonItem>
                <IonLabel>Range</IonLabel>

                <Controller
                  name="range"
                  control={control}
                  defaultValue={true}
                  rules={{
                    required: "Select at least one of the range options",
                  }}
                  render={({ name }) => (
                    <IonSelect
                      name={name}
                      placeholder="Select Range"
                      ref={register({
                        required: "Select at least one of the range options",
                      })}
                    >
                      <IonSelectOption>Pistol</IonSelectOption>
                      <IonSelectOption>Rifle</IonSelectOption>
                      <IonSelectOption>Both</IonSelectOption>
                    </IonSelect>
                  )}
                ></Controller>
              </IonItem>

              {errors && errors["range"] && (
                <IonText color="danger" className="ion-padding-start">
                  <small>{errors["range"].message}</small>
                </IonText>
              )}

              <IonItem>
                <IonLabel>Rental</IonLabel>

                <Controller
                  name="rental"
                  control={control}
                  defaultValue={true}
                  rules={{ required: "You must select a rental option" }}
                  render={({ name }) => (
                    <IonSelect
                      name={name}
                      placeholder="Yes / No"
                      ref={register({
                        required: "You must select a rental option",
                      })}
                    >
                      <IonSelectOption>Yes</IonSelectOption>
                      <IonSelectOption>No</IonSelectOption>
                    </IonSelect>
                  )}
                ></Controller>
              </IonItem>

              {errors && errors["rental"] && (
                <IonText color="danger" className="ion-padding-start">
                  <small>{errors["rental"].message}</small>
                </IonText>
              )}

              <IonListHeader>Range Rules and Release</IonListHeader>

              <RulesCheck
                control={control}
                errors={errors}
                timeOut={0}
              ></RulesCheck>

              <AgreementCheck
                control={control}
                errors={errors}
                currentSignature={signatureString}
                saveSignature={saveSignature}
              ></AgreementCheck>

              {signatureString && (
                <>
                  <IonListHeader>Signature</IonListHeader>

                  <SignatureButton
                    currentSignature={signatureString}
                    saveSignature={saveSignature}
                  ></SignatureButton>
                </>
              )}
            </IonList>

            <div className="ion-padding ion-text-center">
              <IonButton type="submit">
                Get In Line
                <IonIcon icon={arrowForwardCircleSharp} slot="end" />
              </IonButton>
            </div>
          </form>

          <IonLoading isOpen={showLoading} message={"Please wait..."} />
        </IonContent>
      </IonPage>
    </>
  );
};

export default GetInLine;
