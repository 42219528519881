import {
  IonAlert,
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLoading,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { arrowForwardOutline } from "ionicons/icons";
import React, { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import AccountList from "../../components/account/AccountList";
import { UserContext } from "../../providers/UserProvider";
import { auth } from "../../firebase";

const AccountPage: React.FC<RouteComponentProps> = (props) => {
  const [showLogoutAlert, setShowLogoutAlert] = useState<boolean>(false);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Your Account</IonTitle>
          <IonButtons slot="start">
            <IonBackButton text="Back" defaultHref="home" />
          </IonButtons>
          <IonButtons slot="end">
            <IonButton routerLink={"/home"}>
              Continue
              <IonIcon slot="end" icon={arrowForwardOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <UserContext.Consumer>
          {(ctx) => {
            if (!!ctx.customer) {
              return (
                <AccountList
                  customer={ctx.customer}
                  setShowLogoutAlert={setShowLogoutAlert}
                  user={ctx.user!}
                  {...props}
                />
              );
            }

            return <IonLoading isOpen={true} />;
          }}
        </UserContext.Consumer>

        <IonAlert
          isOpen={showLogoutAlert}
          onWillDismiss={() => setShowLogoutAlert(false)}
          header={"Log out"}
          message={"Are you sure you want to log out?"}
          buttons={[
            {
              text: "Cancel",
              role: "cancel",
            },
            {
              text: "Ok",
              handler: () => auth.signOut(),
            },
          ]}
        />
      </IonContent>
    </IonPage>
  );
};

export default AccountPage;
