import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonAlert
} from "@ionic/react";
import React, { useState } from "react";

interface fcProps {
  onRemove(): void

  location: string;
}

const InLineCard: React.FC<fcProps> = ({ onRemove, location }) => {
  const [ showAlert, setShowAlert ] = useState<boolean>(false);

  return (
    <>
      <IonCard
        color="success"
        button
        onClick={ () => setShowAlert(true) }>
        <IonCardHeader>
          <IonCardSubtitle>{ location }</IonCardSubtitle>
          <IonCardTitle>You're in line!</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <p>
            An OGC team member will call out your name when it
          </p>
          <p>
            is your turn to use the range.
          </p>

          <p>You can remove yourself from the line by tapping this card.</p>
        </IonCardContent>
      </IonCard>

      <IonAlert
        isOpen={ showAlert }
        header={ "Leave the line?" }
        message={ "Are you sure you want to leave the line?" }
        buttons={ [
          {
            text: "Cancel",
            role: "cancel",
            handler: () => setShowAlert(false)
          },
          {
            text: "OK",
            handler: onRemove
          }
        ] }
      />
    </>
  );
};

export default InLineCard;
