import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { addSharp } from "ionicons/icons";
import React from "react";
import MinorItem from "../../../components/account/minors/MinorItem";
import { UserContext } from "../../../providers/UserProvider";

const ChildrenListPage = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Children</IonTitle>
          <IonButtons slot="start">
            <IonBackButton text="Back" defaultHref="/account" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <UserContext.Consumer>
          {(ctx) => {
            if (!!ctx.customer && !!ctx.customer.minors) {
              return (
                <IonList lines="full">
                  {Object.keys(ctx.customer.minors).map((key, index) => (
                    <MinorItem
                      key={index}
                      minorKey={key}
                      minor={ctx.customer?.minors![key as any]!}
                    ></MinorItem>
                  ))}
                </IonList>
              );
            }
          }}
        </UserContext.Consumer>
        <IonList>
          <IonItem button routerLink="/account/children/edit">
            <IonIcon icon={addSharp} slot="start" color="primary" />
            <IonLabel>
              <IonText color="primary">Add Child</IonText>
            </IonLabel>
          </IonItem>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default ChildrenListPage;
