import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { arrowForwardCircleSharp } from "ionicons/icons";
import { auth } from "../../firebase";
export interface LoginEmailPageFormData {
  email: string;
}

const LoginEmailPage = () => {
  const { handleSubmit, control, errors } = useForm<LoginEmailPageFormData>();

  const [isEmailSent, setIsEmailSent] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [email, setEmail] = useState<string>();

  const onLogin = (data: LoginEmailPageFormData) => {
    setEmail(data.email);
    setIsEmailSent(true);
    setSendingEmail(true);

    const url = `${window.location.protocol}//${window.location.host}/login/email/confirm?email=${data.email}`
    const actionCodeSettings = { url, handleCodeInApp: true };

    auth
      .sendSignInLinkToEmail(data.email, actionCodeSettings)
      .then(function () {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem("emailForSignIn", data.email);
        setIsEmailSent(true);
      })
      .catch(function (error) {
        // Some error occurred, you can inspect the code: error.code
        console.error(error);
      })
      .finally(() => {
        setSendingEmail(false);
      });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Login using email</IonTitle>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/home" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <form onSubmit={handleSubmit(onLogin)}>
          <IonList lines="none">
            <IonItem>
              <div>
                <h3>Access using an existing account.</h3>
              </div>
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Email</IonLabel>

              <Controller
                render={
                  ({ onChange }) => <IonInput
                    type="email"
                    onIonChange={ onChange } />
                }
                control={control}
                type="email"
                name="email"
                rules={{
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Invalid Email Address",
                  },
                }}
              />
            </IonItem>
            {errors && errors["email"] && (
              <IonText color="danger" className="ion-padding-start">
                <small>{errors["email"].message}</small>
              </IonText>
            )}
            {isEmailSent && (
              <IonText color="warning">
                <p className="ion-padding">
                  A link to access the app was send to {email}.<br /> Check your
                  email on this device. If you not get the email, request to
                  <b>Sign-in</b> again, and make sure email is not on Spam.
                </p>
              </IonText>
            )}
            {!isEmailSent && (
              <div className="ion-padding">
                <IonButton type="submit" expand="block">
                  <IonIcon icon={arrowForwardCircleSharp} slot="end" />
                  Send me a link
                </IonButton>
              </div>
            )}
          </IonList>
        </form>
        <IonLoading isOpen={sendingEmail} />
      </IonContent>
    </IonPage>
  );
};
export default LoginEmailPage;
