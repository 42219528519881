import { IonContent, IonPage, IonText, IonTitle } from "@ionic/react";
import React, { useContext } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { UserContext } from "./providers/UserProvider";
const PrivateRoute: React.FC<RouteProps> = (props) => {
  const { authenticated, loadingAuthState } = useContext(UserContext);

  if (loadingAuthState) {
    return (
      <IonPage>
        <IonContent>
          <IonTitle>
            <IonText>Loading...</IonText>
          </IonTitle>
        </IonContent>
      </IonPage>
    );
  }

  return authenticated ? (
    <Route
      {...props}
      path={props.path}
      exact={props.exact}
      render={props.render}
      component={props.component}
    />
  ) : (
    <Redirect to="/login" />
  );
};

export default PrivateRoute;
