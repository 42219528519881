import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonIcon,
  IonRow,
} from "@ionic/react";
import { arrowForwardCircleSharp } from "ionicons/icons";
import React from "react";

const CompleteAccountCard: React.FC = () => {
  return (
    <IonCard
      button
      color="warning"
      routerDirection="forward"
      routerLink="/account?firstTime=true"
     
    >
      <IonCardHeader>
        <IonCardSubtitle>Account Incomplete</IonCardSubtitle>
        <IonCardTitle>Complete Your Account</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <IonGrid className="ion-no-padding">
          <IonRow className="ion-align-items-center">
            <IonCol size="10">
              <p>
                We need a little more information before getting you on the
                range. Tap here to complete your account.
              </p>
            </IonCol>
            <IonCol className="ion-align-self-center">
              <IonIcon icon={arrowForwardCircleSharp} size="large"></IonIcon>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCardContent>
    </IonCard>
  );
};

export default CompleteAccountCard;
