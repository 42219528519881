import React, { createContext, PropsWithChildren, useState } from "react";

interface Options {
  manualLocation: boolean;
}

export interface ContextProps {
  options: Options;

  saveOption(option: Object): void
}

export const OptionsContext = createContext<ContextProps>({
  options: { manualLocation: false },

  saveOption(option: Object) {}
});

const OptionsProvider = (props: PropsWithChildren<{}>) => {
  const [ options, setOptions ] = useState<Options>({
    manualLocation: false
  });

  const saveOption = (option: Object) => {
    setOptions({ ...options, ...option });
  };

  return <OptionsContext.Provider value={ { options, saveOption } }>
    { props.children }
  </OptionsContext.Provider>;
};

export default OptionsProvider;

