import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import {
  Control,
  Controller,
  DeepMap,
  FieldError,
  ValidateResult,
} from "react-hook-form";
import { CountdownCircleTimer } from 'react-countdown-circle-timer'

export interface RulesCheckProps {
  // control?: Control<Record<string, any>> | undefined;
  control?: Control;
  errors: DeepMap<Record<string, any>, FieldError>;
  timeOut: number;
}

const RulesCheck: React.FC<RulesCheckProps> = ({
  control,
  errors,
  timeOut,
}) => {
  const [openRules, setOpenRules] = useState<boolean>(false);
  const [acceptRules, setAcceptRules] = useState<boolean>(false);
  const [timeLeft, setTimeLeft] = useState(timeOut);

  const validateRules = (value: any): ValidateResult => {
    return acceptRules;
  };

  useEffect(() => {
    if (openRules) {
      const interval = setInterval(() => {
        setTimeLeft((counter) => counter - 1);
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [openRules]);

  return (
    <>
      <IonItem>
        <IonLabel>Range Rules</IonLabel>
        <Controller
          defaultValue={true}
          name="rules"
          control={control}
          render={({ name }) => (
            <IonCheckbox
              name={name}
              onIonChange={() => {
                setOpenRules(true);
              }}
              value="true"
              checked={acceptRules}
              slot="end"
            ></IonCheckbox>
          )}
          rules={{
            validate: validateRules,
          }}
        ></Controller>
        {/* Range rules modal */}
        <IonModal isOpen={openRules} onDidDismiss={() => setOpenRules(false)}>
          <IonHeader translucent>
            <IonToolbar>
              <IonButtons slot="start">
                <CountdownCircleTimer
                  isPlaying
                  duration={timeOut}
                  size={35}
                  strokeWidth={5}
                  colors={[
                    ["#004777", 0.33],
                    ["#F7B801", 0.33],
                    ["#A30000", 0.33]
                  ]}
                >
                  {({ remainingTime }) => remainingTime}
                </CountdownCircleTimer>
              </IonButtons>
              <IonTitle>Range Rules</IonTitle>
              <IonButtons slot="end">
                <IonButton
                  onClick={(e) => {
                    setOpenRules(false);
                  }}
                >
                  Dismiss
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen>
            <IonList lines="none">
              <IonItem>
                <IonText>
                  <ul>
                    <li>Treat every gun as if it were loaded.</li>
                    <li>
                      Always keep the firearm muzzle pointed in safe direction
                      (down range).
                    </li>
                    <li>
                      Eye and ear protection is required before entering the
                      range entrance doors.
                    </li>
                    <li>
                      Keep all firearms inside your assigned stall at all times.
                    </li>
                    <li>
                      Understand the mechanical and handling characteristics of
                      the firearm you are using.
                    </li>
                    <li>
                      Keep your finger off of the trigger until you are ready to
                      fire.
                    </li>
                    <li>
                      Be aware of your target's foreground and background.
                    </li>
                    <li>No rapid fire (more than one shot per second).</li>
                    <li>No failure drills allowed.</li>
                    <li>No drawing from holsters or concealment.</li>
                    <li>
                      Ensure all shots impact the target & bullet trap, no
                      shooting walls, ceilings, or floors.
                    </li>
                    <li>Minimum target distance is 20 feet down range.</li>
                    <li>
                      If you have a problem with your firearm,{" "}
                      <strong>
                        LEAVE IT IN YOUR ASSIGNED STALL POINTED DOWN RANGE{" "}
                      </strong>{" "}
                      and ask a range officer for assistance.{" "}
                      <strong>
                        DO NOT BRING A PROBLEM FIREARM OFF THE RANGE.
                      </strong>
                    </li>
                    <li>
                      All firearms must remain safely cased, or locked open /
                      clear when going into or out of the ranges.
                    </li>
                    <li>
                      No persons are permitted forward of the shooting stalls &
                      firing line <strong>AT ANY TIME.</strong>
                    </li>
                    <li>
                      You are responsible for any and all damage that you cause
                      to the range facility, including but not limited to -
                      target carriers, walls, floors, ceilings, baffles, or
                      stalls.
                    </li>
                  </ul>
                </IonText>
              </IonItem>
              <div className="ion-padding ion-text-center">
                <IonButton
                  onClick={(e) => {
                    setAcceptRules(true);
                    setTimeout(() => {
                      setOpenRules(false);
                    }, 500);
                  }}
                  color={timeLeft > 0 ? 'medium' : 'primary'}
                  disabled={timeLeft > 0}
                >
                  I Accept & Understand the Range Rules
                </IonButton>
              </div>
            </IonList>
          </IonContent>
        </IonModal>
      </IonItem>
      {errors && errors["rules"] && (
        <IonText color="danger" className="ion-padding-start">
          <small>
            In order to access to the range, you must accept the range rules
          </small>
        </IonText>
      )}
    </>
  );
};

export default RulesCheck;
