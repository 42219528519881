import { IonCheckbox, IonItem, IonLabel } from "@ionic/react";
import React, { useState } from "react";

export type SelectPersonCallback = (guid: string, selected: boolean) => void;

// Persona properties...
export interface PersonaRegisterItemProps {
  guid: string;
  firstName: string;
  lastName: string;
  checked: boolean;
  onChangeHandler?: SelectPersonCallback;
}

const PersonaRegisterItem: React.FC<PersonaRegisterItemProps> = ({
  guid,
  firstName,
  lastName,
  checked,
  onChangeHandler,
}) => {
  const [value, setValue] = useState<boolean>(checked);

  const onChange = (guid: string, status: boolean) => {
    if (onChangeHandler !== undefined) {
      onChangeHandler(guid, status);
    }
    return true;
  };
  return (
    <IonItem key={guid}>
      <IonLabel>{`${firstName} ${lastName}`}</IonLabel>
      <IonCheckbox
        color="primary"
        checked={value}
        onIonChange={(e) => {
          setValue(e.detail.checked);
          onChange(guid, e.detail.checked);
        }}
        slot="start"
      ></IonCheckbox>
    </IonItem>
  );
};

export default PersonaRegisterItem;
