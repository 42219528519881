import {
  IonAlert,
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLoading,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import React, { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import ConfirmationCodeForm
  from "../../components/login/phone/ConfirmationCodeForm";
import usePhoneLogin from "../../hooks/usePhoneLogin";

const LoginPhoneConfirmPage: React.FC<RouteComponentProps> = (props) => {
  const [ confirming, setConfirming ] = useState(false);
  const { confirmCode } = usePhoneLogin(window);
  const [ showAlert, setShowAlert ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState("");


  const handleConfirmCode = (code: string) => {
    setConfirming(true);
    confirmCode(code)
      ?.then(() => {
        setConfirming(false);
        props.history.push("/home");
      })
      .catch((error) => {
        console.error(`Unable to confirm code. ${ error.message }`);
        setConfirming(false);
        setErrorMessage(error.message);
        setShowAlert(true);
      });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Log In</IonTitle>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/login/phone" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div className="ion-padding">
          <IonText>
            <p>
              We've sent you a text message. Enter the code you received below.
            </p>
          </IonText>
        </div>

        <ConfirmationCodeForm onConfirmationCodeSubmit={ handleConfirmCode } />

        <IonLoading
          cssClass="my-custom-class"
          isOpen={ confirming }
          onDidDismiss={ () => setConfirming(false) }
        />

        <IonAlert
          isOpen={ showAlert }
          onDidDismiss={ () => setShowAlert(false) }
          header={ "Error" }
          message={ errorMessage }
          buttons={ [ "OK" ] }
        />
      </IonContent>
    </IonPage>
  );
};

export default LoginPhoneConfirmPage;
