import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLoading,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

import React from "react";
import { UserContext } from "../../providers/UserProvider";
import { RouteComponentProps } from "react-router-dom";
import NameEditForm from "../../components/account/NameEditForm";

const NamePage: React.FC<RouteComponentProps> = (props) => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Account / Customer Name</IonTitle>
          <IonButtons slot="start">
            <IonBackButton text="Back" defaultHref="/account" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen className="ion-padding">
        <UserContext.Consumer>
          {(ctx) => {
            if (!!ctx.customer) {
              return (
                <NameEditForm
                  customer={ctx.customer}
                  setCustomer={ctx.setCustomer!}
                  {...props}
                ></NameEditForm>
              );
            }
            return <IonLoading isOpen={true} />;
          }}
        </UserContext.Consumer>
      </IonContent>
    </IonPage>
  );
};

export default NamePage;
