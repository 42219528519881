import firebase from "firebase/app";
import { useEffect, useState } from "react";
import { auth } from "../firebase";

export interface WindowWithRecaptcha extends Window {
  recaptchaVerifier?: firebase.auth.RecaptchaVerifier;
  confirmationResult?: firebase.auth.ConfirmationResult;
}

export interface PhoneLoginFeatures {
  sendSMS: (phoneNumber: string) => Promise<string | null>;
  confirmCode: (code: string) => Promise<void> | undefined;
  init: () => void;
}

const usePhoneLogin = (w: WindowWithRecaptcha): PhoneLoginFeatures => {
  const [verified, setVerified] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState<string>();

  useEffect(() => {
    const sendTextMessage = async () => {
      if (w.recaptchaVerifier) {
        w.confirmationResult = await auth.signInWithPhoneNumber(
          phoneNumber as string,
          w.recaptchaVerifier
        );

        //setTextSent(true);
      } else {
        throw Error("recaptcha is unavailable on window");
      }
    };

    if (verified && phoneNumber) {
      try {
        sendTextMessage();
      } catch (error) {
        if (w.recaptchaVerifier) {
          (w.recaptchaVerifier as any).reset();
        }
      }
    }
  }, [verified, phoneNumber, w]);

  const sendSMS = async (phoneNumber: string): Promise<string | null> => {
    init();

    if (w.recaptchaVerifier) {
      try {
        await w.recaptchaVerifier.verify();
        const confirmationResult = await auth.signInWithPhoneNumber(
          phoneNumber,
          w.recaptchaVerifier
        );

        // Save the result on the window object for the confirm step.
        // TODO: Maybe lift this to a state object and get it off the window.
        w.confirmationResult = confirmationResult;

        return confirmationResult.verificationId;
      } catch (error) {
        w.recaptchaVerifier.clear();
        init();
        throw error;
      }
    }

    throw Error("Recaptcha not available on window.");
  };

  const confirmCode = (
    code: string
  ): Promise<void> | Promise<any> | undefined => {
    if (w.confirmationResult) {
      return w.confirmationResult.confirm(code);
    }

    return Promise.reject({
      message: "Confirmation result unavailable on window.",
    });
  };

  const init = () => {
    setPhoneNumber(undefined);
    setVerified(false);
    // setTextSent(false);

    if (!w.recaptchaVerifier) {
      w.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        "smsRecaptcha",
        {
          size: "invisible",
        }
      );
    }
  };

  return { sendSMS, confirmCode, init };
};

export default usePhoneLogin;
