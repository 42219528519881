import { IonButton, IonIcon, IonInput, IonItem, IonList } from "@ionic/react";
import { checkmarkCircleSharp } from "ionicons/icons";
import React from "react";
import { useForm } from "react-hook-form";

export interface ConfirmationCodeFormProps {
  onConfirmationCodeSubmit: (code: string) => void;
}

interface ConfirmationCodeFormFields {
  code: string;
}

export default function ConfirmationCodeForm(props: ConfirmationCodeFormProps) {
  const { register, handleSubmit } = useForm();

  const onSubmit = async (data: ConfirmationCodeFormFields) => {
    props.onConfirmationCodeSubmit(data.code);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <IonList lines="full" className="ion-no-margin ion-no-padding">
        <IonItem>
          <IonInput
            autocomplete="tel"
            autofocus
            clearOnEdit
            enterkeyhint="send"
            inputmode="tel"
            name="code"
            placeholder="Enter Confirmation Code"
            required
            type="tel"
            ref={register({
              required: "Confirmation code is required",
            })}
          ></IonInput>
        </IonItem>
      </IonList>
      <div className="ion-padding">
        <IonButton expand="block" type="submit" id="sendText">
          <IonIcon slot="start" icon={checkmarkCircleSharp} />
          Confirm
        </IonButton>
      </div>
    </form>
  );
}
