import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLoading,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import { RouteComponentProps } from "react-router-dom";

import { UserContext } from "../../providers/UserProvider";
import EmailEditForm from "../../components/account/EmailFormEdit";

const EmailPage: React.FC<RouteComponentProps> = (props) => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Account / Email</IonTitle>
          <IonButtons slot="start">
            <IonBackButton text="Back" defaultHref="/account" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="ion-padding">
        <UserContext.Consumer>
          {(ctx) => {
            if (!!ctx.customer) {
              return (
                <EmailEditForm
                  customer={ctx.customer}
                  {...props}
                ></EmailEditForm>
              );
            }
            return <IonLoading isOpen={true} />;
          }}
        </UserContext.Consumer>
      </IonContent>
    </IonPage>
  );
};

export default EmailPage;
