import {
  IonAlert,
  IonContent,
  IonHeader,
  IonLoading,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import CreateAccountForm, {
  CreateAccountFormData,
} from "../components/CreateAccountForm";
import useBackend from "../hooks/useBackend";
import { Customer } from "../models/Customer";
import { UserContext } from "../providers/UserProvider";
import moment from "moment";
import { auth } from "../firebase";
import { Country } from "../models/Country";

const ConnectAccountPage: React.FC<RouteComponentProps> = (props) => {
  const [formData, setFormData] = useState<CreateAccountFormData>();

  const [searching, setSearching] = useState<boolean>(false);
  const [creating, setCreating] = useState<boolean>(false);
  // Holds if the userID is an email or not.
  const [providerIsEmail] = useState<boolean>(false);

  const [alertNew, setAlertNew] = useState<boolean>(false);
  const [alertExisting, setAlertExisting] = useState<boolean>(false);
  const [alertPhoneMismatch, setAlertPhoneMismatch] = useState<boolean>(false);
  const [alertAlreadyConnected, setAlertAlreadyConnected] =
    useState<boolean>(false);

  const {
    searchForCustomer,
    createNewCustomerForUser,
    updateCustomer,
    getCountries,
  } = useBackend();
  const [existingCustomer, setExistingCustomer] = useState<Customer>();
  const [countries, setCountries] = useState<Country[]>([]);

  useEffect(() => {
    const callGetCountries = async () => setCountries(await getCountries());

    callGetCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreateAccount = (data: CreateAccountFormData) => {
    console.log(data);
    setSearching(true);
    setFormData(data);

    searchForCustomer(data.phone, data.email).then((result) => {
      if (!result.customer) {
        setAlertNew(true);
      }
      // Search wich account has ben used .
      else if (result.customer && !result.phoneValid) {
        setAlertPhoneMismatch(true);
      } else if (result.customer && result.phoneValid) {
        setExistingCustomer(result.customer);
        setAlertExisting(true);
      }
    });

    setSearching(false);

    // Call the createOrUpdateCustomer() function. It will return a result indicating if the customer
    // was found, and attach the uid to the customer record.

    // If found, alert we found the account and have the user press the confirm button. Dialog
    // should show existing customer information.

    // If not, alert the user we didn't find an existing account and have
    // them confirm to create a new one.

    // Also offer a cancel option that allows them to update the form and try again.
  };

  const { customer } = useContext(UserContext);

  useEffect(() => {
    console.log(customer);
  }, [customer]);

  const handleCreateNew = (
    uid: string,
    setCustomer: React.Dispatch<React.SetStateAction<Customer | null>>
  ) => {
    setCreating(true);

    if (formData) {
      // parse date to object.
      const dob = moment(formData.birthday);
      const birthday = {
        year: Number.parseInt(dob.format("YYYY")),
        month: Number.parseInt(dob.format("M")),
        day: Number.parseInt(dob.format("D")),
      };

      createNewCustomerForUser(
        formData?.phone,
        formData?.email,
        birthday,
        uid
      ).then((c) => {
        setCustomer(c);
        props.history.push("/home");
      });
    }

    setCreating(false);
  };

  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>Create Account</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <UserContext.Consumer>
          {(ctx) => {
            return (
              <>
                {/* {ctx.customer && <Redirect to="/home" />} */}
                {!ctx.customer && (
                  <>
                    <div className="ion-padding">
                      <IonText>
                        <h3>Returning Visitor or Member?</h3>
                      </IonText>
                      <p>
                        Use the phone number and email used to register or
                        purchase,{" "}
                        <strong>
                          even if different than the values used to log in.
                        </strong>
                      </p>
                      <p>We'll pre-fill your account.</p>
                      <IonText>
                        <h4>First time?</h4>
                      </IonText>
                      <p>We'll create a new account for you.</p>
                    </div>
                    <CreateAccountForm
                      countries={countries}
                      phone={ctx.user?.phoneNumber}
                      email={ctx.user?.email}
                      onSubmit={handleCreateAccount}
                    />
                    <IonLoading
                      isOpen={searching}
                      duration={5000}
                      translucent
                      message="Searching for Account..."
                    />
                    <IonLoading
                      isOpen={creating}
                      duration={5000}
                      translucent
                      message="Creating Account..."
                    />

                    <IonAlert
                      isOpen={alertNew}
                      onDidDismiss={() => setAlertNew(false)}
                      header={"Create new account?"}
                      message={`We couldn't find information for ${formData?.email}.`}
                      buttons={[
                        "Cancel",
                        {
                          text: "New Account",
                          handler: () => {
                            if (ctx.user && ctx.setCustomer) {
                              handleCreateNew(ctx.user.uid, ctx.setCustomer);
                            } else {
                              console.error(
                                "User is not set or setCustomer function unavailable from context."
                              );
                            }
                          },
                        },
                      ]}
                    />
                    <IonAlert
                      isOpen={alertExisting}
                      onDidDismiss={() => setAlertExisting(false)}
                      header={"Use this account?"}
                      message={`We've found information for ${formData?.email}. Use this?`}
                      buttons={[
                        "Cancel",
                        {
                          text: "Continue",
                          handler: () => {
                            if (
                              ctx.user &&
                              ctx.setCustomer &&
                              !!existingCustomer
                            ) {
                              // update customer uid'
                              updateCustomer(existingCustomer?.customer!, {
                                uid: ctx.user.uid,
                              }).then(() => {
                                ctx.setCustomer!(existingCustomer!);
                                // redirect home.
                                props.history.push("/home");
                              });
                            }
                          },
                        },
                      ]}
                    />
                    <IonAlert
                      isOpen={alertAlreadyConnected}
                      onDidDismiss={() => setAlertAlreadyConnected(false)}
                      header={"Account Already In Use"}
                      message={`This account is was connected via ${
                        providerIsEmail ? "Phone" : "Email"
                      }, please log in using that method`}
                      buttons={[
                        {
                          text: "Ok",
                          handler: () => {
                            // loging out and redirect
                            auth.signOut().then((res) => {
                              props.history.push("/login");
                            });
                          },
                        },
                      ]}
                    />
                    <IonAlert
                      isOpen={alertPhoneMismatch}
                      onDidDismiss={() => setAlertPhoneMismatch(false)}
                      header={"Phone Number Incorrect"}
                      message={`We found ${formData?.email}, but the phone number entered does not match.`}
                      buttons={["Try Again"]}
                    />
                  </>
                )}
              </>
            );
          }}
        </UserContext.Consumer>
      </IonContent>
    </IonPage>
  );
};

export default ConnectAccountPage;
