import {
  IonButton,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonSelect,
  IonSelectOption,
  IonText
} from "@ionic/react";
import React, { PropsWithChildren, useContext } from "react";
import { arrowForwardCircleSharp } from "ionicons/icons";
import { Controller, useForm } from "react-hook-form";
import { Customer } from "../../models/Customer";
import useBackend from "../../hooks/useBackend";
import { RouteComponentProps } from "react-router-dom";
import countries from "../../shared/countries";
import { UserContext } from "../../providers/UserProvider";

export interface AccountAddressProps extends RouteComponentProps {
  customer: Customer;
}

const AccountAddress: React.FC<PropsWithChildren<AccountAddressProps>> = (
  {
    customer,
    history
  }
) => {
  const { updateCustomer } = useBackend();
  const { setCustomer } = useContext(UserContext);
  const { control, errors, register, handleSubmit } = useForm();
  const countriesWithOther = countries.concat("Other");

  const saveAndContinue = async (formData: Partial<Customer>) => {
    updateCustomer(customer.customer!, formData).then((c) => {
      if (!!setCustomer) setCustomer(c);
      //navigate back to list.
      history.goBack();
    });
  };

  return (
    <>
      <div className="ion-padding">
        <IonText color="muted">
          <p>Get started by entering your phone number.</p>
        </IonText>
      </div>
      <form
        onSubmit={ handleSubmit(saveAndContinue) }
        noValidate
        className="ion-no-padding"
      >
        <IonList>
          <IonItem>
            <IonLabel position="floating">Country *</IonLabel>
            <Controller
              name="country"
              control={ control }
              defaultValue={ customer.country || "" }
              render={ ({ name, onChange, value }) => (
                <IonSelect
                  name={ name }
                  value={ value }
                  placeholder="Country"
                  onIonChange={ onChange }
                  ref={ register }
                >
                  { countriesWithOther.map((option, i) => (
                    <IonSelectOption value={ option } key={ i }>
                      { option }
                    </IonSelectOption>
                  )) }
                </IonSelect>
              ) }
              rules={ { required: "Country is required" } }
            />
          </IonItem>
          { errors && errors["country"] && (
            <IonText color="danger" className="ion-padding-start">
              <small>{ errors["country"].message }</small>
            </IonText>
          ) }

          <IonItem>
            <IonLabel position="floating">Address *</IonLabel>
            <Controller
              name="address"
              control={ control }
              defaultValue={ customer.address || "" }
              render={ ({ name, onChange, value }) => (
                <IonInput
                  placeholder="Address"
                  type="text"
                  name={ name }
                  value={ value }
                  onIonInput={ onChange }
                  ref={ register }
                >
                  { " " }
                </IonInput>
              ) }
              rules={ { required: "The address is required." } }
            />
          </IonItem>
          { errors && errors["address"] && (
            <IonText color="danger" className="ion-padding-start">
              <small>{ errors["address"].message }</small>
            </IonText>
          ) }

          <IonItem>
            <IonLabel position="floating">Address 2</IonLabel>
            <Controller
              name="address2"
              control={ control }
              defaultValue={ customer.address2 || "" }
              render={ ({ name, onChange, value }) => (
                <IonInput
                  type="text"
                  name={ name }
                  value={ value }
                  placeholder="Address 2"
                  onIonInput={ onChange }
                  ref={ register }
                ></IonInput>
              ) }
            />
          </IonItem>

          <IonItem>
            <IonLabel position="floating">City *</IonLabel>
            <Controller
              name="city"
              control={ control }
              defaultValue={ customer.city || ""}
              render={ ({ name, onChange, value }) => (
                <IonInput
                  type="text"
                  name={ name }
                  value={ value }
                  placeholder="City"
                  onIonInput={ onChange }
                  ref={ register }
                ></IonInput>
              ) }
              rules={ { required: "City is a required field." } }
            />
          </IonItem>
          { errors && errors["city"] && (
            <IonText color="danger" className="ion-padding-start">
              <small>{ errors["city"].message }</small>
            </IonText>
          ) }

          <IonItem>
            <IonLabel position="floating">State / Province *</IonLabel>
            <Controller
              name="province"
              control={ control }
              defaultValue={ customer.province || "" }
              render={ ({ name, onChange, value }) => (
                <IonInput
                  type="text"
                  name={ name }
                  value={ value }
                  placeholder="State / Province"
                  onIonInput={ onChange }
                  ref={ register }
                ></IonInput>
              ) }
              rules={ { required: "State is required." } }
            />
          </IonItem>
          { errors && errors["province"] && (
            <IonText color="danger" className="ion-padding-start">
              <small>{ errors["province"].message }</small>
            </IonText>
          ) }

          <IonItem>
            <IonLabel position="floating">Postal Code *</IonLabel>
            <Controller
              name="postal"
              control={ control }
              defaultValue={ customer.postal || "" }
              render={ ({ name, onChange, value }) => (
                <IonInput
                  type="number"
                  name={ name }
                  value={ value }
                  placeholder="Postal code"
                  onIonInput={ onChange }
                  ref={ register }
                ></IonInput>
              ) }
              rules={ { required: "Postal code is required." } }
            />
          </IonItem>
          { errors && errors["postal"] && (
            <IonText color="danger" className="ion-padding-start">
              <small>{ errors["postal"].message }</small>
            </IonText>
          ) }

          <IonItem lines="none"></IonItem>
        </IonList>
        <IonButton
          type="submit"
          size="default"
          expand="block"
          title="Save and continue"
        >
          Save and continue
          <IonIcon icon={ arrowForwardCircleSharp } slot="end" />
        </IonButton>
      </form>
    </>
  );
};

export default AccountAddress;
