import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonBackButton,
  IonList,
  IonItem,
  IonContent,
  IonLabel,
  IonText,
} from "@ionic/react";
import React from "react";

const LoginPhoneCountrySelectPage = () => (
  <IonPage>
    <IonHeader>
      <IonToolbar>
        <IonTitle>Select Country</IonTitle>
        <IonButtons slot="start">
          <IonBackButton defaultHref="/login" />
        </IonButtons>
      </IonToolbar>
    </IonHeader>
    <IonContent>
      <IonList lines="full" className="ion-no-padding ion-no-margin">
        <IonItem button routerLink="/connect?__intl=+1">
          <IonLabel>United States</IonLabel>
          <IonText color="medium">+1</IonText>
        </IonItem>
        <IonItem button routerLink="/connect?__intl=+44">
          <IonLabel>United Kingdom</IonLabel>+44
        </IonItem>
      </IonList>
    </IonContent>
  </IonPage>
);

export default LoginPhoneCountrySelectPage;
