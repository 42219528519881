import {
  IonBackButton, IonButton,
  IonButtons,
  IonContent,
  IonHeader, IonIcon,
  IonList,
  IonLoading,
  IonPage,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import QuestionEditItem, {
  Question,
} from "../../../components/account/questionnaire/QuestionEditItem";
import useBackend from "../../../hooks/useBackend";
import { Questionnaire } from "../../../models/Questionnaire";
import { UserContext } from "../../../providers/UserProvider";
import { arrowForwardCircleSharp } from "ionicons/icons";
import { RouteComponentProps } from "react-router-dom";

const QuestionnairePage: React.FC<RouteComponentProps> = (props) => {
  const { getAdultQuestionnaireByCustomer, updateQuestionnaire } = useBackend();

  const [questionnaire, setQuestionnaire] = useState<Questionnaire>();
  const [customerKey, setCustomerKey] = useState<string | undefined>("");
  const { customer, setCustomer } = useContext(UserContext);

  // Wait for customer key when reload page (F5)
  useEffect(() => {
    setCustomerKey(customer?.customer);
  }, [customer]);

  useEffect(() => {
    const getQuestionnaire = async () => {
      if (!!customerKey) {
        return getAdultQuestionnaireByCustomer(customerKey);
      }
    };
    getQuestionnaire().then((q) => {
      setQuestionnaire(q);
    });
    // We disable next line lint because we don't want to add
    // getAdultQuestionnaireByCustomer reference since it will
    // create an infinite loop on this hook.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerKey]);

  /**
   * Updathe the question value from the questionnaire.
   * @param index key position of the question in questionnaire
   * @param data Question value
   */
  const onQuestionChange = (index: number, data: Question) => {
    // create question object
    const question: Questionnaire = {
      [index]: data,
    };

    // Update questionnaire record.
    updateQuestionnaire(customerKey!, question).then((c) => {
      // merge questionnaire with questionnaire catalog
      setQuestionnaire({
        ...questionnaire,
        ...c.questionnaire,
      });

      if (!!setCustomer) setCustomer(c);
    });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Questionnaire</IonTitle>
          <IonButtons slot="start">
            <IonBackButton text="Back" defaultHref="/account" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <UserContext.Consumer>
          {(ctx) => {
            if (!!ctx.customer && !!questionnaire) {
              return (
                <IonList lines="full">
                  {Object.keys(questionnaire).map((v, i) => (
                    <QuestionEditItem
                      key={i}
                      index={i}
                      question={questionnaire[i]}
                      onSubmitHandler={onQuestionChange}
                    ></QuestionEditItem>
                  ))}

                  <IonButton
                    expand="full"
                    title="Save and continue"
                    onClick={ () => props.history.goBack() }>
                    Save and continue
                    <IonIcon icon={ arrowForwardCircleSharp } slot="end" />
                  </IonButton>
                </IonList>
              );
            } else {
              return <IonLoading isOpen></IonLoading>;
            }
          }}
        </UserContext.Consumer>
      </IonContent>
    </IonPage>
  );
};

export default QuestionnairePage;
