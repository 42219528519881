import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLoading,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import AccountAddress from "../../components/account/Address";
import { UserContext } from "../../providers/UserProvider";

const AddressPage: React.FC<RouteComponentProps> = (props) => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Account / Address</IonTitle>
          <IonButtons slot="start">
            <IonBackButton text="Back" defaultHref="/account" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="ion-padding">
        <UserContext.Consumer>
          {(ctx) => {
            if (!!ctx.customer) {
              return <AccountAddress customer={ctx.customer} {...props} />;
            }
            return <IonLoading isOpen={true} />;
          }}
        </UserContext.Consumer>
      </IonContent>
    </IonPage>
  );
};

export default AddressPage;
