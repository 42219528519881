import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonList,
  IonLoading,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";

import QuestionEditItem, {
  Question,
} from "../../../components/account/questionnaire/QuestionEditItem";
import useBackend from "../../../hooks/useBackend";
import { Questionnaire } from "../../../models/Questionnaire";
import { UserContext } from "../../../providers/UserProvider";

interface ChildrenQuestionnaireProps
  extends RouteComponentProps<{
    key?: string;
  }> {}
const ChildrenQuestionnairePage: React.FC<ChildrenQuestionnaireProps> = ({
  match,
}) => {
  const { getMinorQuestionnaireByMinor, updateMinorQuestionnaire } =
    useBackend();
  const { key } = match.params;
  const [questionnaire, setQuestionnaire] = useState<Questionnaire>();
  const [customerKey, setCustomerKey] = useState<string | undefined>("");
  const { customer } = useContext(UserContext);

  // Wait for customer key when reload page (F5)
  useEffect(() => {
    setCustomerKey(customer?.customer);
  }, [customer]);

  useEffect(() => {
    // Already get a merge questionnaire from catalog and minor's
    // This is the preferred way to call an async fetch from db
    const getQuestionnaire = async () => {
      if (!!customerKey && !!key) {
        return getMinorQuestionnaireByMinor(customerKey, key);
      }
    };
    getQuestionnaire().then((q) => {
      setQuestionnaire(q);
    });
    // We disable next line lint because we don't want to add
    // getMinorsQuerstionnaireByMinor reference since it will
    // create an infinite loop on this hook.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerKey, key]);

  /**
   * Updathe the question value from the questionnaire.
   * @param index key position of the question in questionnaire
   * @param data Question value
   */
  const onQuestionChange = (index: number, data: Question) => {
    // create question object
    const question: Questionnaire = {
      [index]: data,
    };

    // Update minor's questionnaire record.
    if (!!customerKey && !!key) {
      updateMinorQuestionnaire(customerKey, key, question).then((c) => {
        // merge minor's questionnaire with questionnaire catalog
        setQuestionnaire({
          ...questionnaire,
          ...c.minors![key as any].questionnaire,
        });
      });
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Questionnaire</IonTitle>
          <IonButtons slot="start">
            <IonBackButton text="Back" defaultHref="/account/children" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <UserContext.Consumer>
          {(ctx) => {
            if (!!ctx.customer && !!questionnaire) {
              return (
                <IonList lines="full">
                  {Object.keys(questionnaire).map((v, i) => (
                    <QuestionEditItem
                      key={i}
                      index={i}
                      question={questionnaire[i]}
                      onSubmitHandler={onQuestionChange}
                    ></QuestionEditItem>
                  ))}
                </IonList>
              );
            } else {
              return <IonLoading isOpen></IonLoading>;
            }
          }}
        </UserContext.Consumer>
      </IonContent>
    </IonPage>
  );
};

export default ChildrenQuestionnairePage;
