import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { arrowForwardCircleSharp } from "ionicons/icons";
import { UserContext } from "../../providers/UserProvider";
import { Controller, useForm } from "react-hook-form";
import useBackend from "../../hooks/useBackend";
import { Customer } from "../../models/Customer";
import PhoneNumberInput from "../../components/PhoneNumberInput";
import PhoneNumber from "awesome-phonenumber";
import { Country } from "../../models/Country";

const ContactInformationPage: React.FC<RouteComponentProps> = (props) => {
  const { customer, setCustomer } = useContext(UserContext);
  const [countries, setCountries] = useState<Country[]>([]);
  const { updateCustomer, getCountries } = useBackend();
  const { control, errors, register, handleSubmit } = useForm();

  useEffect(() => {
    const callGetCountries = async () => setCountries(await getCountries());

    callGetCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveAndContinue = async (formData: Partial<Customer>) => {
    updateCustomer(customer!.customer!, formData).then((c) => {
      //navigate back to list.
      setCustomer!(c);
      props.history.goBack();
    });
  };

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Name, Phone, Email, Address</IonTitle>
            <IonButtons slot="start">
              <IonBackButton text="Back" defaultHref="/account" />
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <form onSubmit={handleSubmit(saveAndContinue)} noValidate>
            <IonList lines="full">
              <IonItem>
                <IonLabel position="floating">First name</IonLabel>
                <Controller
                  name="firstName"
                  control={control}
                  defaultValue={customer?.firstName || ""}
                  render={({ name, onChange, value }) => (
                    <IonInput
                      type="text"
                      name={name}
                      value={value}
                      onIonInput={onChange}
                      ref={register}
                    ></IonInput>
                  )}
                  rules={{ required: "First name is required." }}
                />
              </IonItem>

              {errors && errors["firstName"] && (
                <IonText color="danger" className="ion-padding-start">
                  <small>{errors["firstName"].message}</small>
                </IonText>
              )}

              <IonItem>
                <IonLabel position="floating">Last name</IonLabel>
                <Controller
                  name="lastName"
                  control={control}
                  defaultValue={customer?.lastName || ""}
                  render={({ name, onChange, value }) => (
                    <IonInput
                      type="text"
                      name={name}
                      value={value}
                      onIonInput={onChange}
                      ref={register}
                    ></IonInput>
                  )}
                  rules={{ required: "Last name is required." }}
                />
              </IonItem>

              {errors && errors["lastName"] && (
                <IonText color="danger" className="ion-padding-start">
                  <small>{errors["lastName"].message}</small>
                </IonText>
              )}

              <IonItem>
                <IonLabel position="floating">Email address * </IonLabel>
                <Controller
                  name="email"
                  control={control}
                  defaultValue={customer?.email || ""}
                  render={({ name, onChange, value }) => (
                    <IonInput
                      type="email"
                      name={name}
                      value={value}
                      onIonInput={onChange}
                      ref={register}
                    ></IonInput>
                  )}
                  rules={{
                    required: "Email address is required.",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "This is not a valid email",
                    },
                  }}
                />
              </IonItem>

              {errors && errors["email"] && (
                <IonText color="danger" className="ion-padding-start">
                  <small>{errors["email"].message}</small>
                </IonText>
              )}

              <Controller
                control={control}
                name="phone"
                defaultValue={customer!.phone || ""}
                render={({ onChange, value }) => (
                  <PhoneNumberInput
                    value={value}
                    onChange={onChange}
                    countries={countries}
                  />
                )}
                rules={{
                  required: "Phone is required",
                  validate: (value) =>
                    new PhoneNumber(value).isValid() || "Invalid Phone Number",
                }}
              ></Controller>
            </IonList>

            <IonButton
              type="submit"
              size="default"
              expand="block"
              title="Save and continue"
            >
              Save
              <IonIcon icon={arrowForwardCircleSharp} slot="end" />
            </IonButton>
          </form>
        </IonContent>
      </IonPage>
    </>
  );
};

export default ContactInformationPage;
