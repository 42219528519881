import { IonButton, IonIcon, IonText, IonList } from "@ionic/react";
import React, { useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import PhoneNumberInput from "../PhoneNumberInput";
import { arrowForwardCircleSharp } from "ionicons/icons";
import PhoneNumber from "awesome-phonenumber";
import { Country } from "../../models/Country";

export interface PhoneFormProps {
  phone?: string;
  onSubmit: SubmitHandler<PhoneFormData>;
  countries: Country[];
}

export interface PhoneFormData {
  phone: string;
}

const PhoneEditForm: React.FC<PhoneFormProps> = ({ phone, onSubmit, countries }) => {
  const { handleSubmit, errors, control, reset } = useForm<PhoneFormData>();

  useEffect(() => {
    reset({
      phone: phone || "",
    });
  }, [phone, reset]);

  return (
    <>
      <div className="ion-padding">
        <IonText color="muted" className="ion-padding">
          <h2>Enter your phone number</h2>
        </IonText>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <IonList lines="full" className="ion-no-margin ion-no-padding">
          <Controller
            control={control}
            name="phone"
            defaultValue={phone}
            render={({ onChange, value }) => (
              <PhoneNumberInput
                value={value}
                onChange={onChange}
                countries={countries} />
            )}
            rules={{
              required: "Phone is required",
              validate: (value) =>
                new PhoneNumber(value).isValid() || "Invalid Phone Number",
            }}
          ></Controller>
        </IonList>
        <div className="ion-padding">
          <IonText color="danger">{errors.phone?.message}</IonText>
          <IonButton expand="block" type="submit">
            Continue
            <IonIcon icon={arrowForwardCircleSharp} slot="end" />
          </IonButton>
        </div>
      </form>
    </>
  );
};

export default PhoneEditForm;
