import {
  IonAlert,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonIcon,
  IonRow
} from "@ionic/react";
import { arrowForwardCircleSharp } from "ionicons/icons";
import React, { useContext, useEffect, useState } from "react";
import { RangeLocation } from "../../models/RangeLocation";
import { OptionsContext } from "../../providers/OptionsProvider";
import { useHistory } from "react-router-dom";
import useLocation from "../../hooks/useLocation";

export interface RegisterCardProps {
  range?: RangeLocation;
}

const RegisterCard: React.FC = () => {
  const [ showAlert, setShowAlert ] = useState<boolean>(false);
  const history = useHistory();
  const { range, locationError } = useLocation();
  const {
    options: { manualLocation },
    saveOption
  } = useContext(OptionsContext);

  useEffect(() => {
    if (locationError) {
      setShowAlert(true);
    }

  }, [ locationError ]);

  // Hook for update minor.
  return (
    <>
      {
        manualLocation ?
          <IonCard
            color="primary"
            button
            onClick={ () => history.push("/get-in-line") }>
            <IonCardContent>
              <IonGrid className="ion-no-padding">
                <IonRow className="ion-align-items-center">
                  <IonCol size="10">
                    <h2>Welcome To The Orlando Gun Club!!! </h2>
                    <p>
                      Tap on this card to log
                      into the range!
                    </p>
                  </IonCol>
                  <IonCol className="ion-align-self-center">
                    <IonIcon
                      icon={ arrowForwardCircleSharp }
                      size="large"
                    ></IonIcon>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCardContent>

          </IonCard>
          :
          <IonCard
            color={ !!range ? "primary" : "medium" }
            button={ !!range }
            onClick={ () => {
              if (range) {
                history.push("/get-in-line");
              }
            } }
          >
            <IonCardHeader>
              {
                <IonCardSubtitle>{ range?.name }</IonCardSubtitle>
              }
              <IonCardTitle>Get In Line</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <IonGrid className="ion-no-padding">
                {
                  range && !manualLocation ?
                    <IonRow className="ion-align-items-center">
                      <IonCol size="10">
                        <h2>Welcome { range.name } shooting gallery!!</h2>

                        <p>
                          Tap on this card to start the check in process.
                        </p>
                      </IonCol>
                      <IonCol className="ion-align-self-center">
                        <IonIcon
                          icon={ arrowForwardCircleSharp }
                          size="large"
                        ></IonIcon>
                      </IonCol>
                    </IonRow>
                    :
                    <IonRow>
                      <IonCol>
                        <p>
                          You need to be closer to one of you facilities to
                          register for the range / get in queue
                        </p>
                      </IonCol>
                    </IonRow>
                }
              </IonGrid>
            </IonCardContent>
          </IonCard>
      }

      <IonAlert
        isOpen={ showAlert }
        header={ "Location Error" }
        subHeader={ locationError?.message }
        message={ "Do you want to select the shooting range manually?" }
        buttons={
          [
            "Cancel",
            {
              text: "Ok",
              handler: (_) => {
                saveOption({ manualLocation: true });

                setShowAlert(false);
              }
            }
          ]
        }
      />
    </>
  );
};

export default RegisterCard;
