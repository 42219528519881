import {
  IonAlert,
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { trash } from "ionicons/icons";
import MinorEditForm, {
  MinorEditFormData,
} from "../../../components/account/minors/MinorEditForm";
import {
  EditQuestionCallback,
  Question,
} from "../../../components/account/questionnaire/QuestionEditItem";
import useBackend from "../../../hooks/useBackend";
import { Minor } from "../../../models/Minor";
import { Questionnaire } from "../../../models/Questionnaire";
import { UserContext } from "../../../providers/UserProvider";

interface ChildrenPageProps
  extends RouteComponentProps<{
    key?: string;
  }> {}

const ChildrenPage: React.FC<ChildrenPageProps> = ({ match, history }) => {
  const { updateMinor, createMinor, deleteMinor } = useBackend();
  const { customer, setCustomer } = useContext(UserContext);
  const [minorFormData, setMinorFormData] = useState<
    MinorEditFormData | undefined
  >({});

  // Children key
  const { key } = match.params;
  // Get the initial values of the questionnaire.
  const [questionnaire, setQuestionnaire] = useState<Questionnaire>();
  // Add this to prevent useEffect trigger when customer object changes"
  const [customerKey, setCustomerKey] = useState<string | undefined>("");
  // Manage delete alert
  const [isDeleting, setIsDeleting] = useState(false);

  // Wait for customer key when reload page (F5)
  useEffect(() => {
    setCustomerKey(customer?.customer);
  }, [customer]);

  useEffect(() => {
    if (!!customer && !!customer.minors) {
      let minor = customer.minors[match.params.key as any];
      if (!!minor) {
        // Parse date of birth
        let dob: string | undefined;
        if (!!minor.dateOfBirth) {
          dob = new Date(
            minor.dateOfBirth.year,
            minor.dateOfBirth.month - 1,
            minor.dateOfBirth.day
          ).toISOString();
        }

        setMinorFormData({
          firstName: minor.firstName,
          lastName: minor.lastName,
          dateOfBirth: dob,
        });
      }
    }
  }, [match.params.key, customer]);

  const handleOnSubmit = (data: MinorEditFormData) => {
    if (!!customer && !!customer.customer && !!data) {
      // Parse date string.
      const dob = new Date(data.dateOfBirth!);

      // Inicial minor payload.
      let minor: Partial<Minor> = {
        firstName: data.firstName!,
        lastName: data.lastName!,
        dateOfBirth: {
          day: dob.getDate(),
          month: dob.getMonth() + 1,
          year: dob.getFullYear(),
        },
        questionnaire: questionnaire,
      };

      // Update payload to include customer contact information.
      if (match.params.key === undefined) {
        minor = {
          ...minor,
          ...{
            phone: customer.phone!,
            address: customer.address!,
            address2: customer.address2!,
            country: customer.country!,
            city: customer.city!,
            province: customer.province!,
            postal: customer.postal!,
          },
        };
      }
      saveAndContinue(minor, customer.customer, match.params.key);
    }
  };

  const saveAndContinue = async (
    minor: Partial<Minor>,
    customerKey: string,
    minorKey?: string
  ) => {
    if (!!setCustomer) {
      if (!!minorKey) {
        updateMinor(customerKey, minorKey, minor).then((c) => {
          setCustomer(c);
        });
      } else {
        createMinor(customerKey, minor).then((c) => {
          setCustomer(c);
        });
      }
      // Navigate to list.
      history.goBack();
    }
  };

  /**Questionnaire effects */
  const { getMinorQuestionnaireByMinor, getMinorQuestionnaire } = useBackend();

  useEffect(() => {
    // Already get a merge questionnaire from catalog and minor's
    // This is the preferred way to call an async fetch from db
    const getQuestionnaire = async () => {
      if (!!customerKey && !!key) {
        // get merged questinnaire data from db and minor's data.
        return getMinorQuestionnaireByMinor(customerKey, key);
        //Prevents to render twice due key
      } else if (!!customerKey) {
        // Get virgin questionnaire data from db
        return getMinorQuestionnaire();
      }
    };
    getQuestionnaire().then((q) => {
      setQuestionnaire(q);
    });
    // We disable next line lint because we don't want to add
    // getMinorsQuerstionnaireByMinor reference since it will
    // create an infinite loop on this hook.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerKey, key]);

  const onQuestionChange: EditQuestionCallback = (
    index: number,
    value: Question
  ) => {
    if (value.answer === undefined) return;

    // create object
    const question: Questionnaire = {
      [index]: value,
    };

    // merge values
    const merged = { ...questionnaire, ...question };
    setQuestionnaire(merged);
  };

  const validateQuestionnaire = (questionnaire?: Questionnaire): boolean => {
    if (!!questionnaire) {
      if (Object.keys(questionnaire).length < 3) return false;

      for (let k of Object.keys(questionnaire)) {
        let question = questionnaire[k as any];
        if (question.answer === undefined) return false;
      }
    } else {
      return false;
    }
    return true;
  };

  const deleteMinorByKey = (customerKey?: string, key?: string) => {
    if (!!customerKey && !!key) {
      deleteMinor(customerKey, key).then((c) => {
        if (!!setCustomer) {
          setCustomer(c);
          // Navigate to child list.
          history.goBack();
        }
      });
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Account / Children </IonTitle>
          <IonButtons slot="start">
            <IonBackButton text="Back" defaultHref="/account/children" />
          </IonButtons>
          <IonButtons slot="end">
            {!!key && (
              <IonButton title="delete" onClick={(e) => setIsDeleting(true)}>
                <IonIcon icon={trash}></IonIcon>
              </IonButton>
            )}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <UserContext.Consumer>
          {(ctx) => {
            if (!!ctx.customer) {
              return (
                <>
                  <MinorEditForm
                    onSubmit={handleOnSubmit}
                    minor={minorFormData}
                    questionnaire={questionnaire}
                    onHandleQuestionChange={onQuestionChange}
                    onQuestionnaireValidate={validateQuestionnaire}
                  ></MinorEditForm>
                </>
              );
            }
          }}
        </UserContext.Consumer>
        <IonAlert
          isOpen={isDeleting}
          onDidDismiss={() => setIsDeleting(false)}
          header={"Delete current minor?"}
          message={`Are you sure you want delete this minor from list`}
          buttons={[
            {
              text: "Ok",
              handler: () => {
                deleteMinorByKey(customerKey, key);
              },
            },
            {
              text: "Cancel",
              role: "cancel",
            },
          ]}
        />
      </IonContent>
    </IonPage>
  );
};

export default ChildrenPage;
