import {
  IonButton,
  IonDatetime,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonText,
} from "@ionic/react";
import PhoneNumber from "awesome-phonenumber";
import { arrowForwardCircleSharp } from "ionicons/icons";
import React, { useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import PhoneNumberInput from "./PhoneNumberInput";
import moment from "moment";
import { Country } from "../models/Country";

export interface CreateAccountFormProps {
  phone?: string | null;
  email?: string | null;
  birthday?: Date;
  onSubmit: SubmitHandler<CreateAccountFormData>;
  countries: Country[];
}

export interface CreateAccountFormData {
  phone: string;
  email: string;
  birthday: string;
}

const CreateAccountForm: React.FC<CreateAccountFormProps> = (props) => {
  const {
    handleSubmit,
    errors,
    control,
    reset,
  } = useForm<CreateAccountFormData>();

  useEffect(() => {
    reset({
      phone: props.phone || "",
      email: props.email || "",
    });
  }, [props.phone, props.email, reset]);

  return (
    <form onSubmit={handleSubmit(props.onSubmit)}>
      <IonList lines="full" className="ion-no-margin ion-no-padding">
        <Controller
          control={control}
          name="phone"
          defaultValue={props.phone}
          render={({ onChange, value }) => (
            <PhoneNumberInput value={value} onChange={onChange} countries={props.countries} />
          )}
          rules={{
            required: "Phone is required",
            validate: (value) =>
              new PhoneNumber(value).isValid() || "Invalid Phone Number",
          }}
        />

        <IonItem>
          <IonLabel position="fixed">
            <IonText>Email</IonText>
          </IonLabel>
          <Controller
            render={({ onChange, onBlur, value }) => (
              <IonInput value={value} onIonChange={onChange} />
            )}
            control={control}
            defaultValue={props.email}
            name="email"
            rules={{
              required: "Email is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Invalid Email Address",
              },
            }}
          />
        </IonItem>

        <IonItem>
          <IonLabel position="fixed">Date of birth</IonLabel>
          <Controller
            name="birthday"
            control={control}
            render={({ name, onChange, value }) => (
              <IonDatetime
                name={name}
                value={value}
                onIonChange={onChange}
                displayTimezone="utc"
              ></IonDatetime>
            )}
            rules={{
              validate: (value) => {
                console.log("birthday", value);
                // Get current date
                const now = moment();
                const dob = moment(value);
                const age = now.diff(dob, "years");
                return (
                  age >= 21 ||
                  "You must have at least 21 years old to access range, else you need a guardian in order to shoot at the ranges"
                );
              },
            }}
          />
        </IonItem>
      </IonList>
      <div className="ion-padding">
        <IonText color="danger" className="ion-padding-start">
          {errors.phone?.message}
          {errors.email?.message}
          {errors.birthday?.message}
        </IonText>
        <IonButton expand="block" type="submit">
          Continue
          <IonIcon icon={arrowForwardCircleSharp} slot="end" />
        </IonButton>
      </div>
    </form>
  );
};

export default CreateAccountForm;
