import { db } from "../firebase";
import { Customer } from "../models/Customer";
import { SearchResult } from "../models/SearchResult";

import PhoneNumber from "awesome-phonenumber";
import { Questionnaire } from "../models/Questionnaire";
import { Minor } from "../models/Minor";
import { useContext } from "react";
import { UserContext } from "../providers/UserProvider";
import { RangeLocation } from "../models/RangeLocation";
import * as countryCode from "country-code-lookup";
import { Country } from "../models/Country";
import { omitBy, isNil } from "lodash";

const useBackend = () => {
  const { setCustomer } = useContext(UserContext);
  /**
   * Searches the database for a customer with matching phone and email.
   *
   * @param phone
   * @param email
   */
  const searchForCustomer = async (
    phone: string,
    email: string
  ): Promise<SearchResult> => {
    // Default to an empty result
    let result: SearchResult = {
      customer: null,
      phoneValid: true,
      message: "An account with this email does not exist.",
    };

    const snapshot = await db
      .ref("/customers")
      .orderByChild("email")
      .equalTo(email)
      .limitToFirst(1)
      .once("value");

    if (!snapshot.exists()) {
      return result;
    }

    snapshot.forEach((s) => {
      result.customer = s.val() as Customer;
    });

    if (result.customer) {
      const phoneParsed = new PhoneNumber(phone, "US");

      if (
        result.customer.phone !== phoneParsed.getNumber() &&
        result.customer.phone !== phoneParsed.getNumber("significant")
      ) {
        result.phoneValid = false;
        result.message =
          "An account with this email was found, but the phone number does not match.";
      }

      result.message = "Account found.";
    }

    return result;
  };

  const searchForCustomerByEmail = async (
    email: string
  ): Promise<SearchResult> => {
    // Default to an empty result
    let result: SearchResult = {
      customer: null,
      phoneValid: true,
      message: "An account with this email does not exist.",
    };

    const snapshot = await db
      .ref("/customers")
      .orderByChild("email")
      .equalTo(email)
      .limitToFirst(1)
      .once("value");

    if (!snapshot.exists()) {
      return result;
    }

    snapshot.forEach((s) => {
      result.customer = s.val() as Customer;
    });

    if (result.customer) {
      result.message = "Account found.";
    }

    return result;
  };

  const createNewCustomerForUser = async (
    phone: string,
    email: string,
    dateOfBirth: { year: number; month: number; day: number },
    uid: string
  ) => {
    const newCustomerRef = await db.ref("/customers").push({
      phone,
      email,
      uid,
      dateOfBirth,
    });

    await newCustomerRef.update({
      customer: newCustomerRef.key,
    });

    const newCustomerSnapshot = await newCustomerRef.once("value");

    return newCustomerSnapshot.val() as Customer;
  };

  const getAdultQuestionnaire = async (): Promise<Questionnaire> => {
    const snapshot = await db.ref("/questions/adult").once("value");
    return snapshot.val() as Questionnaire;
  };

  const getAdultQuestionnaireByCustomer = async (
    customerKey: string
  ): Promise<Questionnaire> => {
    // Get adult questionnaire
    const currentQuestionnaire = await getAdultQuestionnaire();

    // Get the actual customer questionnaire
    const snapshot = await db
      .ref(`/customers/${customerKey}/questionnaire`)
      .once("value");
    const q = snapshot.val() as Questionnaire;

    // Merge catalog with customer questionnaire
    const merged = { ...currentQuestionnaire, ...q };
    // return the merged object
    return merged;
  };

  const getMinorQuestionnaire = async (): Promise<Questionnaire> => {
    const snapshot = await db.ref("/questions/minor").once("value");
    return snapshot.val() as Questionnaire;
  };

  const getMinorQuestionnaireByMinor = async (
    customerKey: string,
    minorKey: string
  ): Promise<Questionnaire> => {
    // Get minor questionnaire
    const currentQuestionnaire = await getMinorQuestionnaire();

    const snapshot = await db
      .ref(`/customers/${customerKey}/minors/${minorKey}/questionnaire`)
      .once("value");
    const q = snapshot.val() as Questionnaire;

    const merged = { ...currentQuestionnaire, ...q } as Questionnaire;
    return merged;
  };

  const updateCustomer = async (
    customerKey: string,
    customer: Partial<Customer>
  ): Promise<Customer> => {
    const customerRef = db.ref(`/customers/${customerKey}`);

    await customerRef.update(customer);

    // Get latest updated record
    // Retrieve customer information.
    return getCustomerByKey(customerKey);
  };

  /**
   * Updates questionnaire data.
   * @param customer a partial customer object
   */
  const updateQuestionnaire = async (
    customerKey: string,
    questionnaire: Questionnaire
  ): Promise<Customer> => {
    // Get back customer record.
    let questionnaireRef = db.ref(`/customers/${customerKey}/questionnaire`);

    const questionnaireSnapshot = await questionnaireRef.once("value");

    const q = questionnaireSnapshot.val() as Questionnaire;

    // merge objects
    const mergedObject = { ...q, ...questionnaire };

    // update merged data
    await questionnaireRef.update(mergedObject);

    // Retrieve customer information.
    return getCustomerByKey(customerKey);
  };

  /**
   * Create a child record
   * @param {string} customerKey: customer unique key index
   * @param {Minor} minor
   */
  const createMinor = async (customerKey: string, minor: Partial<Minor>) => {
    // Create reference for child data
    const minorsRef = db.ref(`/customers/${customerKey}/minors`);

    // Create new record, let firebase create the key
    await minorsRef.push(omitBy(minor, isNil));

    // Retrieve customer information.
    return getCustomerByKey(customerKey);
  };

  const updateMinor = async (
    customerKey: string,
    minorKey: string,
    minor: Partial<Minor>
  ) => {
    // Create reference for child data
    const minorsRef = db.ref(`/customers/${customerKey}/minors/${minorKey}`);

    // Create new record, let firebase create the key
    await minorsRef.update(minor);

    return getCustomerByKey(customerKey);
  };

  const deleteMinor = async (
    customerKey: string,
    minorKey: string
  ): Promise<Customer> => {
    // Create reference for child data

    const minorsRef = db.ref(`/customers/${customerKey}/minors/${minorKey}`);

    // Create new record, let firebase create the key
    await minorsRef.remove();

    return getCustomerByKey(customerKey);
  };

  const updateMinorQuestionnaire = async (
    customerKey: string,
    minorKey: string,
    questionnaire: Questionnaire
  ) => {
    // Create reference for child questionnaire data.
    const questionnaireRef = db.ref(
      `/customers/${customerKey}/minors/${minorKey}/questionnaire`
    );
    const questionnaireSnapshot = await questionnaireRef.once("value");
    // Get curren questionnaire data.
    const currentQuestionnaire = questionnaireSnapshot.val() as Questionnaire;
    // Merge current questionnaire with incoming data.
    const mergedQuestionnaire = { ...currentQuestionnaire, ...questionnaire };
    // update minors questionnaire
    questionnaireRef.update(mergedQuestionnaire); // Retrieve customer information.

    // Get customer data.
    return getCustomerByKey(customerKey);
  };

  /**
   * Gets the entire customer data.
   * @param {string} customerKey: Query value to search for.
   */
  const getCustomerByKey = async (customerKey: string): Promise<Customer> => {
    const dbRef = db.ref(`/customers/${customerKey}/`);

    const snapshot = await dbRef.once("value");

    const customer = snapshot.val() as Customer;

    if (customer.activeRegistration) {
      const registration = await db
        .ref(`/registrations/${customer.activeRegistration}`)
        .once("value");

      const { location, queueId } = registration.val();

      customer.activeRegistration = {
        id: registration.key!,
        location,
        queueId,
      };
    }

    return customer;
  };

  /**
   * Creates a new range registration and returns it.
   * @param {Registration} registration
   * @returns {Promise<string>}
   */
  const createRegistration = async (registration: any): Promise<string> => {
    const dbRef = await db
      .ref("registrations")
      .push({
        ...registration,
        created: Date.now(),
        updated: Date.now(),
      })
      .catch((reason) => {
        throw reason;
      });

    const key = dbRef.key!;
    // Set the registration to pending in queue.
    await db.ref("complete").child(key).set({
      status: "pending",
    });

    // Set registration active in customer record.
    await db
      .ref(`customers/${registration.customer}`)
      .update({ activeRegistration: key });

    if (!!setCustomer) {
      getCustomerByKey(registration.customer).then((c) => setCustomer(c));
    }

    return key!;
  };

  const syncRegistration = async (registrationId: string) => {
    await db.ref("sync/" + registrationId).set({
      status: "pending",
    });
  };

  const removeRegistration = async (
    customerId: string,
    registrationId: string,
    location: string,
    queueId: string
  ) => {
    await db
      .ref(`customers/${customerId}`)
      .update({ activeRegistration: null });
    console.log("customer removed");
    await db.ref(`registrations/${registrationId}`).remove();
    console.log("registration removed");
    await db.ref(`queues/${location}/${queueId}`).remove();
    console.log("queue removed");
    const customer = await getCustomerByKey(customerId);
    setCustomer?.(customer);
  };

  const getLocations = async () => {
    const snapshot = await db.ref("/locations").once("value");
    const locations: RangeLocation[] = [];

    snapshot.forEach((childSnapshot) => {
      const childKey = childSnapshot.key;
      const childData = childSnapshot.val();

      locations.push({ id: childKey!, ...childData });
    });

    return locations;
  };

  // used once  to insert data in database; missing service account
  const insertCountries = async () => {
    let countries = [
      "Andorra",
      "Canada",
      "Finland",
      "Iceland",
      "Liechtenstein",
      "Monaco",
      "San Marino",
      "Spain",
      "Australia",
      "Chile",
      "France",
      "Italy",
      "Lithuania",
      "Netherlands",
      "Singapore",
      "Sweden",
      "Austria",
      "Czech Republic",
      "Germany",
      "Ireland",
      "Luxembourg",
      "New Zealand",
      "Slovakia",
      "Switzerland",
      "Belgium",
      "Denmark",
      "Greece",
      "Japan",
      "Malta",
      "Norway",
      "Slovenia",
      "Taiwan",
      "Brunea",
      "Estonia",
      "Hungary",
      "Latvia",
      "Mexico",
      "Portugal",
      "South Korea",
      "United Kingdom",
      "Bermuda",
    ];
    countries.sort();

    for (const item of countries) {
      try {
        const regionCode = countryCode.byCountry(item);

        const country = {
          name: item,
          regionCode: regionCode.internet,
          callingCode: PhoneNumber.getCountryCodeForRegionCode(
            regionCode.internet
          ),
        };

        await db.ref(`/phoneCountries/${item}`).set(country);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getCountries = async () => {
    const snapshot = await db.ref("/phoneCountries").once("value");
    const countries = snapshot.toJSON() as { [keys: string]: Country };
    const usa = countries["United States"];
    delete countries["United States"];

    if (usa) {
      return [usa, ...Object.values(countries)];
    }

    return Object.values(countries);
  };

  return {
    searchForCustomer,
    searchForCustomerByEmail,
    createNewCustomerForUser,
    getAdultQuestionnaire,
    getAdultQuestionnaireByCustomer,
    getMinorQuestionnaire,
    getMinorQuestionnaireByMinor,
    updateCustomer,
    updateQuestionnaire,
    updateMinorQuestionnaire,
    createMinor,
    updateMinor,
    deleteMinor,
    createRegistration,
    getCustomerByKey,
    removeRegistration,
    getLocations,
    insertCountries,
    getCountries,
    syncRegistration,
  };
};

export default useBackend;
