import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonInput,
  IonItem,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";

const RegistrationPage = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Register to ..</IonTitle>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/home" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <form>
          <IonGrid>
            <IonRow justify-content-center>
              <IonCol align-self-center size-md="6" size-lg="5" size-xs="12">
                <div text-center>
                  <h3>Create your account!</h3>
                </div>
                <div className="ion-padding">
                  <IonItem>
                    <IonInput
                      name="name"
                      type="text"
                      placeholder="Name"
                      required
                    ></IonInput>
                  </IonItem>
                  <IonItem>
                    <IonInput
                      name="email"
                      type="email"
                      placeholder="your@email.com"
                      required
                    ></IonInput>
                  </IonItem>
                  <IonItem>
                    <IonInput
                      name="password"
                      type="password"
                      placeholder="Password"
                      required
                    ></IonInput>
                  </IonItem>
                  <IonItem>
                    <IonInput
                      name="confirm"
                      type="password"
                      placeholder="Password again"
                      required
                    ></IonInput>
                  </IonItem>
                </div>
                <div>
                  <IonButton size="large" type="submit" expand="block">
                    Register
                  </IonButton>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default RegistrationPage;
