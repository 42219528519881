import {
  IonButton,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonSelect,
  IonSelectOption,
  IonText
} from "@ionic/react";
import React, { PropsWithChildren } from "react";
import { arrowForwardCircleSharp } from "ionicons/icons";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Customer } from "../../models/Customer";
import { RouteComponentProps } from "react-router-dom";

export interface IdentificationEditFormProps extends RouteComponentProps {
  setCustomer: React.Dispatch<React.SetStateAction<Customer | null>>;
  customer: Customer;
  onSubmit: SubmitHandler<IdentificationFormData>;
}

export interface IdentificationFormData {
  idType: string;
  idNumber: string;
}

const IdentificationEditForm: React.FC<PropsWithChildren<IdentificationEditFormProps>> = (
  { customer, onSubmit }
) => {
  const { control, errors, register, handleSubmit } = useForm();

  return (
    <>
      <form onSubmit={ handleSubmit(onSubmit) }>
        <IonList>
          <IonItem>
            <IonLabel position="floating">Identification Type *</IonLabel>
            <Controller
              name="idType"
              control={ control }
              defaultValue={ customer.idType || "" }
              render={ ({ name, onChange, value }) => (
                <IonSelect
                  name={ name }
                  value={ value }
                  onIonChange={ onChange }
                  ref={ register }
                >
                  <IonSelectOption value="Drivers Licence">
                    Drivers License
                  </IonSelectOption>
                  <IonSelectOption value="Passport">Passport</IonSelectOption>
                  <IonSelectOption value="State ID Card">
                    State ID Card
                  </IonSelectOption>
                  <IonSelectOption value="Other">Other</IonSelectOption>
                </IonSelect>
              ) }
              rules={ {
                required: "A valid Identification type is required."
              } }
            />
          </IonItem>
          { errors && errors["idType"] && (
            <IonText color="danger" className="ion-padding-start">
              <small>{ errors["idType"].message }</small>
            </IonText>
          ) }
          <IonItem>
            <IonLabel position="floating">Identification number *</IonLabel>
            <Controller
              name="idNumber"
              control={ control }
              defaultValue={ customer.idNumber || "" }
              render={ ({ name, onChange, value }) => (
                <IonInput
                  type="text"
                  name={ name }
                  value={ value }
                  onIonInput={ onChange }
                  ref={ register }
                ></IonInput>
              ) }
              rules={ { required: "ID number is required." } }
            />
          </IonItem>
          { errors && errors["idNumber"] && (
            <IonText color="danger" className="ion-padding-start">
              <small>{ errors["idNumber"].message }</small>
            </IonText>
          ) }

          <IonItem lines="none"></IonItem>
        </IonList>

        <IonButton
          type="submit"
          size="default"
          expand="block"
          title="Save and continue"
        >
          Save and continue
          <IonIcon icon={ arrowForwardCircleSharp } slot="end" />
        </IonButton>
      </form>
    </>
  );
};

export default IdentificationEditForm;
