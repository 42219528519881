//United States first and order the rest alphabetically
let countries = [
  "Andorra",
  "Canada",
  "Finland",
  "Iceland",
  "Liechtenstein",
  "Monaco",
  "San Marino",
  "Spain",
  "Australia",
  "Chile",
  "France",
  "Italy",
  "Lithuania",
  "Netherlands",
  "Singapore",
  "Sweden",
  "Austria",
  "Czech Republic",
  "Germany",
  "Ireland",
  "Luxembourg",
  "New Zealand",
  "Slovakia",
  "Switzerland",
  "Belgium",
  "Denmark",
  "Greece",
  "Japan",
  "Malta",
  "Norway",
  "Slovenia",
  "Taiwan",
  "Brunea",
  "Estonia",
  "Hungary",
  "Latvia",
  "Mexico",
  "Portugal",
  "South Korea",
  "United Kingdom",
  "Bermuda",
];
countries.sort();
countries = ["United States", ...countries];
export default countries;
