/* Core CSS required for Ionic components to work properly */
import { IonApp, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import "@ionic/react/css/core.css";
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/typography.css";
import React from "react";
import { Redirect, Route } from "react-router-dom";
import "./App.css";
import AccountPage from "./pages/account/AccountPage";
import ConnectAccountPage from "./pages/ConnectAccountPage";
import HomePage from "./pages/HomePage";
import LoginEmailPage from "./pages/login/LoginEmailPage";
import LoginPhoneConfirmPage from "./pages/login/LoginPhoneConfirmPage";
import LoginPhoneCountrySelectPage
  from "./pages/login/LoginPhoneCountrySelectPage";
import LoginPhonePage from "./pages/login/LoginPhonePage";
import UserProvider from "./providers/UserProvider";
import GetInLine from "./pages/account/GetInLine";
import NamePage from "./pages/account/NamePage";
import PhonePage from "./pages/account/PhonePage";
import EmailPage from "./pages/account/EmailPage";
import AddressPage from "./pages/account/AddressPage";
import IdentificationPage from "./pages/account/IdentificationPage";
import ChildrenListPage from "./pages/account/children/ChildrenListPage";
import ChildrenPage from "./pages/account/children/ChildrenPage";
import QuestionnairePage from "./pages/account/questionnaire/QuestionnairePage";
import RegistrationPage from "./pages/Registration";

import PrivateRoute from "./PrivateRoute";
import LoginEmailConfirmPage from "./pages/login/LoginEmailConfirm";
import ChildrenQuestionnairePage
  from "./pages/account/children/ChildrenQuestionnairePage";
import OptionsProvider from "./providers/OptionsProvider";
import ContactInformationPage from "./pages/account/ContactInformationPage";
import * as Sentry from "@sentry/react";
import { browserHistory } from "./history";

function App() {
  return (
    <UserProvider>
      <OptionsProvider>
        <IonApp>
          <IonReactRouter history={ browserHistory }>
            <IonRouterOutlet>
              <Route
                exact
                path="/login"
                render={ (props) => <LoginPhonePage { ...props } /> }
              ></Route>
              <Route
                exact
                path="/login/phone/country"
                render={ (props) => <LoginPhoneCountrySelectPage /> }
              ></Route>
              <Route
                exact
                path="/login/phone/confirm"
                render={ (props) => <LoginPhoneConfirmPage { ...props } /> }
              ></Route>
              <Route
                exact
                path="/login/email"
                render={ (props) => <LoginEmailPage /> }
              ></Route>
              <Route
                exact
                path="/login/email/confirm"
                render={ (props) => <LoginEmailConfirmPage { ...props } /> }
              ></Route>
              <Route
                exact
                path="/connect"
                render={ (props) => <ConnectAccountPage { ...props } /> }
              ></Route>
              <PrivateRoute
                exact
                path="/home"
                render={ (props) => <HomePage /> }
              ></PrivateRoute>
              <PrivateRoute
                exact
                path="/get-in-line"
                render={ (props) => <GetInLine { ...props } /> }
              ></PrivateRoute>
              <Route
                exact
                path="/register"
                render={ (props) => <RegistrationPage /> }
              ></Route>
              <PrivateRoute
                exact
                path="/account"
                render={ (props) => <AccountPage { ...props } /> }
              ></PrivateRoute>
              <PrivateRoute
                exact
                path="/account/contact-information"
                render={ (props) => <ContactInformationPage { ...props } /> }
              ></PrivateRoute>
              <PrivateRoute
                exact
                path="/account/name"
                render={ (props) => <NamePage { ...props } /> }
              ></PrivateRoute>
              <PrivateRoute
                exact
                path="/account/email"
                render={ (props) => <EmailPage { ...props } /> }
              ></PrivateRoute>
              <PrivateRoute
                exact
                path="/account/phone"
                render={ (props) => <PhonePage { ...props } /> }
              ></PrivateRoute>
              <PrivateRoute
                exact
                path="/account/address"
                render={ (props) => <AddressPage { ...props } /> }
              ></PrivateRoute>
              <PrivateRoute
                exact
                path="/account/identification"
                render={ (props) => <IdentificationPage { ...props } /> }
              ></PrivateRoute>
              <PrivateRoute
                exact
                path="/account/questionnaire"
                render={ (props) => <QuestionnairePage { ...props } /> }
              ></PrivateRoute>
              <PrivateRoute
                exact
                path="/account/children"
                render={ (props) => <ChildrenListPage /> }
              ></PrivateRoute>
              <PrivateRoute
                path="/account/children/edit/:key?"
                render={ (props) => <ChildrenPage { ...props } /> }
              ></PrivateRoute>
              <PrivateRoute
                exact
                path="/account/children/:key/questionnaire"
                render={ (props) => <ChildrenQuestionnairePage { ...props } /> }
              ></PrivateRoute>
              <Route exact path="/" render={ () => <Redirect to="/home" /> } />
            </IonRouterOutlet>
          </IonReactRouter>
        </IonApp>
      </OptionsProvider>
    </UserProvider>
  );
}

export default Sentry.withProfiler(App);
