import { IonItem, IonLabel } from "@ionic/react";
import React from "react";
import { Minor } from "../../../models/Minor";

export interface MinorItemProps {
  minorKey: string;
  minor: Minor;
}

const MinorItem: React.FC<MinorItemProps> = ({ minorKey, minor }) => {
  return (
    <IonItem button routerLink={`/account/children/edit/${minorKey}`}>
      <IonLabel>
        {minor.firstName} {minor.lastName}
      </IonLabel>
    </IonItem>
  );
};

export default MinorItem;
