import {
  IonButton,
  IonContent,
  IonDatetime,
  IonIcon,
  IonInput,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonText
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { arrowForwardCircleSharp } from "ionicons/icons";
import { Questionnaire } from "../../../models/Questionnaire";
import QuestionEditItem, {
  EditQuestionCallback,
  Question
} from "../questionnaire/QuestionEditItem";

export interface MinorEditFormProps {
  minor?: MinorEditFormData;
  questionnaire?: Questionnaire;
  onSubmit: SubmitHandler<MinorEditFormData>;
  onHandleQuestionChange: EditQuestionCallback;
  onQuestionnaireValidate: QuestionnaireValidation;
}

export type QuestionnaireValidation = (q?: Questionnaire) => boolean;

export interface MinorEditFormData {
  dateOfBirth?: string;
  firstName?: string;
  lastName?: string;
}

const MinorEditForm: React.FC<MinorEditFormProps> = (
  {
    minor,
    questionnaire,
    onSubmit,
    onHandleQuestionChange,
    onQuestionnaireValidate
  }
) => {
  const {
    control,
    errors,
    register,
    handleSubmit,
    setValue
  } = useForm<MinorEditFormData>();

  useEffect(() => {
    if (minor) {
      setValue("firstName", minor.firstName);
      setValue("lastName", minor.lastName);
      setValue("dateOfBirth", minor.dateOfBirth);
    }
  }, [ minor, setValue ]);
  const [ questionnaireError, setQuestionnaireError ] = useState<string>();

  const onHandleQuestion: EditQuestionCallback = (
    index: number,
    value: Question
  ) => {
    onHandleQuestionChange(index, value);
  };

  const onHandleSubmit: SubmitHandler<MinorEditFormData> = (
    data: MinorEditFormData
  ) => {
    //validate
    setQuestionnaireError(undefined);
    console.log(!onQuestionnaireValidate(questionnaire));
    if (!onQuestionnaireValidate(questionnaire)) {
      setQuestionnaireError(
        "You must complete questionnaire in order to add a minor record"
      );
      return false;
    }

    //Call the event
    onSubmit(data);
  };

  return (
    <IonContent fullscreen>
      <form
        onSubmit={ handleSubmit(onHandleSubmit) }
        noValidate
        className="ion-no-padding"
      >
        <IonList lines="full">
          <IonItemDivider>
            <IonLabel>Name</IonLabel>
          </IonItemDivider>
          <IonItem>
            <IonLabel position="floating">First name</IonLabel>
            <Controller
              name="firstName"
              defaultValue=""
              control={ control }
              render={ ({ name, onChange }) => (
                <IonInput
                  type="text"
                  name={ name }
                  placeholder="First name"
                  onIonChange={ onChange }
                  ref={ register }
                ></IonInput>
              ) }
              rules={ { required: "First name is required." } }
            />
          </IonItem>

          { errors && errors["firstName"] && (
            <IonText color="danger" className="ion-padding-start">
              <small>{ errors["firstName"].message }</small>
            </IonText>
          ) }
          <IonItem>
            <IonLabel position="floating">Last name</IonLabel>
            <Controller
              name="lastName"
              control={ control }
              defaultValue=""
              render={ ({ name, onChange }) => (
                <IonInput
                  type="text"
                  placeholder="Last name"
                  name={ name }
                  onIonChange={ onChange }
                  ref={ register }
                ></IonInput>
              ) }
              rules={ { required: "Last name is required." } }
            />
          </IonItem>

          { errors && errors["lastName"] && (
            <IonText color="danger" className="ion-padding-start">
              <small>{ errors["lastName"].message }</small>
            </IonText>
          ) }

          <IonItem>
            <IonLabel position="floating">Date of birth *</IonLabel>
            <Controller
              name="dateOfBirth"
              control={ control }
              defaultValue=""
              render={ ({ name, onChange }) => (
                <IonDatetime
                  name={ name }
                  onIonChange={ onChange }
                  placeholder="Select date"
                  ref={ register }
                ></IonDatetime>
              ) }
              rules={ { required: "A valid date is required." } }
            />
          </IonItem>

          { errors && errors["dateOfBirth"] && (
            <IonText color="danger" className="ion-padding-start">
              <small>A valid date is required.</small>
            </IonText>
          ) }

          <IonItemDivider>
            <IonLabel>Questionnaire</IonLabel>
          </IonItemDivider>
          { !!questionnaire &&
          Object.keys(questionnaire).map((v, i) => (
            <QuestionEditItem
              key={ i }
              index={ i }
              question={ questionnaire[i] }
              onSubmitHandler={ onHandleQuestion }
            ></QuestionEditItem>
          )) }
          { questionnaireError && (
            <div className="ion-padding">
              <IonText color="danger">
                <p>{ questionnaireError }</p>
              </IonText>
            </div>
          ) }
        </IonList>

        <IonButton expand="block" type="submit" title="Save & continue">
          Save and continue
          <IonIcon icon={ arrowForwardCircleSharp } slot="end" />
        </IonButton>
      </form>
    </IonContent>
  );
};

export default MinorEditForm;
